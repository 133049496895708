@font-face {
  font-family: "Bell MT";
  src: url("../fonts/bell-mt.ttf") format("truetype");
}

:root {
  font-family: "Bell MT" !important;
}

#carousel .owl-item,
#carousel .owl-item.active.center,
#reccomended .owl-item,
#reccomended .owl-item.active.center {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
}

#results_map_view,
#search_mobile {
  z-index: 9999999999999999 !important;
}

.marker_info,
body {
  font-family: "Bell MT";
}

@media only screen and (min-width: 992px) {
  nav#menu.main-menu {
    /* display: block !important */
    display: none !important;
  }

  #mm-menu.main-menu,
  header .btn_mobile {
    /* display: none !important */
    display: block !important;
  }

  .main-menu {
    position: relative;
    z-index: 9;
    width: auto;
    top: 8px;
    right: 15px;
    float: right;
    -moz-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    color: #fff;
  }

  .main-menu ul,
  .main-menu ul li {
    position: relative;
    /* display: inline-block; */
    display: block;

    margin: 0;
    padding: 0;
  }

  .main-menu ul a,
  .main-menu ul li a {
    color: #ccc;
    position: relative;
    margin: 0;
    padding: 5px 10px;
    display: block;
    white-space: nowrap;
  }

  .main-menu > ul > li span > a {
    /* color: #fff; */
    color: #000;
    padding: 0 8px 15px;
    font-size: 0.875rem;
    font-weight: 500;
  }

  .main-menu > ul > li span:hover > a {
    opacity: 0.7;
  }

  header.header_in .main-menu > ul > li span > a,
  header.sticky .main-menu > ul > li span > a {
    color: #444;
  }

  header.header_in .main-menu > ul > li span:hover > a,
  header.sticky .main-menu > ul > li span:hover > a {
    opacity: 1;
    color: #004dda;
  }

  .main-menu ul ul {
    position: absolute;
    z-index: 1;
    visibility: hidden;
    left: 3px;
    top: 93%;
    margin: 0;
    display: block;
    padding: 0;
    background: #fff;
    min-width: 200px;
    -webkit-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.175);
    -moz-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.175);
    transform: scale(0.4);
    transform-origin: 10% top;
    transition:
      0.2s,
      _ 0.2s,
      _ 0.2s;
    opacity: 0;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    -webkit-transition: 0.2s;
    -ms-transition: 0.2s;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }

  .main-menu ul ul:before {
    bottom: 100%;
    left: 15%;
    border: 7px solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #fff;
    margin-left: -7px;
  }

  .main-menu ul ul li {
    display: block;
    height: auto;
    padding: 0;
  }

  .main-menu ul ul li a {
    font-size: 0.8125rem;
    color: #555;
    border-bottom: 1px solid #ededed;
    display: block;
    padding: 15px 10px;
    line-height: 1;
  }

  .main-menu ul li:hover > ul {
    padding: 0;
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transition-duration: 0s, 0.2s, 0.2s;
    transition-duration: 0s, 0.2s, 0.2s;
  }

  .main-menu ul ul li:first-child a:hover {
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    -ms-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
  }

  .main-menu ul ul li:last-child a {
    border-bottom: none;
  }

  .main-menu ul ul li:last-child a:hover {
    -webkit-border-radius: 0 0 3px 3px;
    -moz-border-radius: 0 0 3px 3px;
    -ms-border-radius: 0 0 3px 3px;
    border-radius: 0 0 3px 3px;
  }

  .main-menu ul ul li:hover > a {
    background-color: #f9f9f9;
    color: #004dda;
    padding-left: 15px;
  }
}

.main-menu ul ul.second_level_right {
  left: auto;
  right: 3px;
}

.main-menu ul ul.second_level_right:before {
  right: 15%;
  left: auto;
}

.main-menu ul ul li span a {
  font-weight: 400 !important;
}

.main-menu ul ul li span a:hover {
  background-color: #f9f9f9;
  color: #004dda;
  padding-left: 18px;
  opacity: 1;
}

.main-menu ul ul li span a:after {
  font-family: ElegantIcons;
  content: "\35";
  float: right;
  font-size: 1rem;
  margin-top: -2px;
}

.main-menu ul ul ul {
  position: absolute;
  border-top: 0;
  z-index: 1;
  height: auto;
  left: 100%;
  top: 0;
  margin: 0;
  padding: 0;
  background: #fff;
  min-width: 190px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.main-menu ul ul ul.third_level_left {
  right: 100%;
  left: auto;
}

.main-menu ul ul ul li a {
  border-bottom: 1px solid #ededed !important;
}

.main-menu ul ul ul li:last-child a {
  border-bottom: none !important;
}

.main-menu ul ul ul:before {
  border-width: 0;
  margin-left: 0;
}

@media only screen and (max-width: 991px) {
  nav#menu {
    display: none !important;
  }

  .mm-menu {
    background: #fff;
  }

  .main-menu {
    top: 0;
    right: 0;
    float: none;
  }

  ul.mm-listview {
    line-height: 25px;
  }

  ul.mm-listview li a {
    color: #ccc;
    display: block;
  }
}

.closebt,
.custom-search-input-2.inner .nice-select:after {
  right: 15px;
}

.main_title_2 p,
.main_title_3 p {
  line-height: 1.4;
  font-weight: 300;
}

#menu.fake_menu {
  display: none !important;
  visibility: hidden !important;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  /* background: #f8f8f8; */
  background: #ffffff;
  
  
  // padding-bottom: 80px;

  background: url(../images/4858794.jpg);
  /* background-position: center; */
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;


  font-family: "Bell MT";
  // font-family: "Bell MT";
  // font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  color: #161634;
  font-style: normal;
  line-height: 150%;
  /* 36px */
  font-size: 16px;

  font-weight: 400;
  letter-spacing: -0.16px;
  background-color: #fff;
  // padding-top: 90px;
}

.btn_infobox_get_directions:hover,
a.btn_infobox_phone:hover,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333;
}

/* .add_bottom_30,
.step,
p {
  margin-bottom: 30px;
} */

#hero_video .wrapper p strong,
.hero_single .wrapper p strong,
.hero_single.version_2 .wrapper p strong,
.hero_single.version_4 .wrapper p strong,
.hero_single.version_5 .wrapper p strong,
strong {
  font-weight: 600;
}

label {
  // font-weight: 500;
  // margin-bottom: 3px;
}

hr {
  margin: 30px 0;
  border-color: #ddd;
  opacity: 1;
}

ol,
ul {
  list-style: none;
  margin: 0 0 25px;
  padding: 0;
}

.main_title,
.main_title_2 {
  text-align: center;
  margin-bottom: 45px;
  position: relative;
}

h1.main_title_in {
  font-size: 1.625rem;
  margin: 0 0 25px;
}

.main_title span {
  content: "";
  display: block;
  width: 60px;
  height: 3px;
  margin: auto auto 20px;
  opacity: 1;
  background-color: #999;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.main_title_3 a:after,
a {
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
}

.main_title.animated span {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.main_title_2 span {
  width: 120px;
  height: 2px;
  background-color: #e1e1e1;
  display: block;
  margin: auto;
}

#toTop:hover,
.btn_1.full-width.purchase,
.main_title_2 span em,
.main_title_3 span,
.marker-cluster-large div,
.marker-cluster-medium div,
.marker-cluster-small div,
.sub_header_in,
a.btn_1.full-width.purchase {
  background-color: #004dda;
}

.main_title_2 span em {
  width: 60px;
  height: 2px;
  display: block;
  margin: auto;
}

.main_title_2 h2 {
  margin: 25px 0 0;
}

.main_title_2 p {
  font-size: 1.5rem;
}

.main_title_3 {
  position: relative;
  border-left: 2px solid #e1e1e1;
  padding-left: 15px;
}

.main_title_3 span {
  width: 2px;
  height: 30px;
  position: absolute;
  left: -2px;
  top: 0;
  display: block;
}

.main_title_3 h2,
.main_title_3 h3 {
  font-size: 1.625rem;
  margin: 0;
  display: inline-block;
}

.main_title_3 p {
  font-size: 1.125rem;
  margin-bottom: 20px;
}

.main_title_3 a {
  display: inline-block;
  right: 0;
  bottom: 0;
  position: absolute;
}

.main_title_3 a:after {
  font-family: ElegantIcons;
  content: "\24";
  font-size: 1.3125rem;
  transition: 0.3s ease-in-out;
  position: relative;
  top: 5px;
  margin-left: 4px;
}

a {
  color: #004dda;
  text-decoration: none;
  transition: 0.3s ease-in-out;
  outline: 0;
}

.btn_1,
.btn_add,
a.btn_1,
a.btn_add {
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  outline: 0;
  background: #004dda;
  text-decoration: none;
}

a:focus,
a:hover {
  color: #111;
  text-decoration: none;
  outline: 0;
}

.btn_1,
a.btn_1 {
  border: none;
  padding: 15px 30px;
  color: #fff;
  text-align: center;
  line-height: 1;
  transition: 0.3s ease-in-out;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.btn_home_align,
ul.counter li {
  text-align: right;
}

.btn_1:hover,
a.btn_1:hover {
  background-color: #ffc107;
  color: #222 !important;
}

.btn_1.full-width,
a.btn_1.full-width {
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
}

.btn_1.full-width.purchase:hover,
a.btn_1.full-width.purchase:hover {
  background-color: #32a067;
  color: #fff !important;
}

.btn_1.full-width.wishlist,
a.btn_1.full-width.wishlist {
  border-color: #555;
  color: #555;
}

.btn_1.full-width.wishlist:hover,
a.btn_1.full-width.wishlist:hover {
  color: #fff !important;
  background-color: #555;
  border-color: #555;
}

.btn_1.medium,
a.btn_1.medium {
  font-size: 1rem;
  padding: 18px 40px;
}

.btn_1.rounded,
a.btn_1.rounded {
  -webkit-border-radius: 25px !important;
  -moz-border-radius: 25px !important;
  -ms-border-radius: 25px !important;
  border-radius: 25px !important;
}

.btn_1.outline,
a.btn_1.outline {
  border: 2px solid #004dda;
  color: #004dda;
  padding: 11px 40px;
  background: 0 0;
}

.btn_1.outline:hover,
a.btn_1.outline:hover {
  background: #0054a6;
  color: #fff;
  border-color: #0054a6;
}

#custom-search-input .search-query:focus,
.btn_1.outline:focus,
.btn_infobox_get_directions:focus,
a.btn_1.outline:focus,
a.btn_infobox_phone:focus {
  outline: 0;
}

.btn_add,
a.btn_add {
  border: none;
  color: #fff !important;
  transition: 0.3s ease-in-out;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  line-height: 1 !important;
  padding: 10px 15px !important;
  position: relative;
  top: 2px;
}

.btn_map_in,
.closebt,
a.btn_map_in {
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  cursor: pointer;
}

a.btn_filt,
a.btn_filt_map,
a.btn_map {
  padding: 8px 12px 8px 10px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 5px;
  line-height: 1;
  font-size: 0.75rem;
  font-weight: 600;
}

.btn_add:hover,
a.btn_add:hover {
  background-color: #ffc107;
  color: #222 !important;
  opacity: 1 !important;
}

.follow_us ul li a i:hover,
footer ul li a:hover,
footer ul.links li a:hover:after {
  opacity: 1;
  color: #004dda;
}

.closebt {
  transition: 0.3s ease-in-out;
  position: absolute;
  font-size: 1.5rem;
  top: 10px;
  color: #fff;
  opacity: 0.6;
  width: 30px;
  height: 30px;
}

.closebt:hover {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  opacity: 1;
}

a.btn_map {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.strip.list_view a.wish_bt.liked:after,
.tools_map a:hover,
a.btn_filt:hover,
a.btn_filt_map:hover,
a.btn_map:hover {
  color: #004dda;
}

a.btn_map:before {
  font-family: ElegantIcons;
  content: "\e081";
  display: inline-block;
  margin-right: 5px;
}

a.btn_filt,
a.btn_filt_map {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.08);
  position: relative;
}

a.btn_filt:before,
a.btn_filt_map:before {
  font-family: ElegantIcons;
  content: "\67";
  display: inline-block;
  margin-right: 5px;
  font-size: 0.8125rem;
}

.btn_map_in,
a.btn_map_in {
  border: none;
  background: #004dda;
  outline: 0;
  text-decoration: none;
  padding: 15px 30px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
  display: inline-block;
  line-height: 1;
  transition: 0.3s ease-in-out;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

#filters,
header.header.sticky,
header.header_in {
  border-bottom: 1px solid #ededed;
}

.btn_map_in:hover,
a.btn_map_in:hover {
  background: #0054a6;
  color: #fff;
}

#custom-search-input input[type="submit"]:hover,
#newsletter input[type="submit"]:hover,
.custom-search-input-2 input[type="submit"]:hover,
.hero_single.version_4 .wrapper input[type="submit"],
.search_mob_wp input[type="submit"] {
  color: #222;
  background-color: #ffc107;
}

header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding: 20px 30px;
  z-index: 99999999;
  background-color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0);
}

header.header .logo_sticky,
header.header.sticky .logo_normal {
  display: none;
}

header.header.sticky {
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  background-color: #fff;
  /* padding: 15px 20px */
  padding: 20px 30px;
}

a.box_cat_home ul li,
header.header.sticky .logo_sticky {
  display: inline-block;
}

header.header_in {
  padding: 10px 0;
  background-color: #fff;
  position: relative;
}

header.header_in.is_sticky,
header.header_in.map_view {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}

.sticky ul#top_menu li a.login:before,
.sticky ul#top_menu li a.wishlist_bt_top:before,
.strip.list_view a.wish_bt:hover:after,
.strip.map_view a.wish_bt:hover:after,
header.header_in ul#top_menu li a {
  color: #444;
}

.sub_header_in {
  padding: 20px 0;
}

.sub_header_in.sticky_header {
  margin-top: 58px;
}

.sub_header_in h1 {
  color: #fff;
  margin: 0;
  font-size: 1.625rem;
}

ul#top_menu {
  float: right;
  margin: 0 0 0 10px;
  padding: 0;
  font-size: 0.8125rem;
}

ul#top_menu li {
  float: left;
  margin: 0 15px 0 0;
  line-height: 1;
}

ul#top_menu li:last-child {
  margin-right: 0;
  position: relative;
  top: 0;
}

.box_1 h3,
.call_section h2,
.grid-gallery ul li figcaption a,
.secondary_nav ul li a.active,
ul#top_menu li a,
ul.share-buttons li a:hover {
  /* color: #fff */
  color: #000;
}

ul#top_menu li a:hover {
  color: #fff;
  opacity: 0.7;
}

ul#top_menu li a.login,
ul#top_menu li a.wishlist_bt_top {
  display: block;
  width: 22px;
  height: 23px;
  position: relative;
  top: 8px;
  -moz-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

#newsletter input[type="submit"],
footer ul li a {
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
}

ul#top_menu li a.login:before,
ul#top_menu li a.wishlist_bt_top:before {
  font-family: Glyphter;
  font-size: 1.3125rem;
  text-indent: 0;
  position: absolute;
  left: 0;
  top: 0;
  font-weight: 400;
  line-height: 1;
}

ul#top_menu li a.login,
ul#top_menu li a.wishlist_bt_top {
  text-indent: -9999px;
  overflow: hidden;
}

ul#top_menu li a.wishlist_bt_top:before {
  content: "\0043";
}

ul#top_menu li a.login:before {
  content: "\0042";
}

header.header_in .hamburger-inner,
header.header_in .hamburger-inner::after,
header.header_in .hamburger-inner::before,
header.sticky .hamburger-inner,
header.sticky .hamburger-inner::after,
header.sticky .hamburger-inner::before {
  background-color: #444;
}

#logo,
.filters_listing ul li:first-child,
.filters_listing.version_2 ul li:first-child,
.filters_listing.version_3 ul li:first-child {
  float: left;
}

main {
  background-color: #f8f8f8;
  position: relative;
  z-index: 1;
}

main.pattern {
  background: url(../fonts/pattern_3.svg) center center/cover no-repeat #f8f8f8;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  position: relative;
  z-index: 1;
}

@media (min-width: 576px) {
  .collapse.dont-collapse-sm {
    display: block !important;
    height: auto !important;
    visibility: visible !important;
  }
}

.layer,
.leaflet-zoom-anim .leaflet-zoom-hide,
.wow {
  visibility: hidden;
}

footer {
  border-top: 1px solid #ededed;
  /* background-color: #fff */
  background-color: hsl(215deg 23.53% 20%);
  margin-top: 5px;
}

footer h3,
footer h2 {
  margin: 0 0 15px;
  font-size: 1.125rem;
  color: #fff;
}

#search_mobile .form-group,
.box_detail ul li,
.filter_type ul li,
.leaflet-bottom .leaflet-control-scale,
footer ul li,
ul.info_booking li {
  margin-bottom: 5px;
}

footer ul li a {
  transition: 0.3s ease-in-out;
  display: inline-block;
  position: relative;
  /* color: #555 */
  color: #fff;
}

footer ul li a i {
  margin-right: 10px;
  color: #fff;
}

footer ul.links li a:hover {
  -webkit-transform: translate(5px, 0);
  -moz-transform: translate(5px, 0);
  -ms-transform: translate(5px, 0);
  -o-transform: translate(5px, 0);
  transform: translate(5px, 0);
}

footer ul.links li a:after {
  font-family: ElegantIcons;
  content: "\24";
  position: absolute;
  margin-left: 5px;
  top: 1px;
  opacity: 0;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

footer ul.contacts li {
  padding-left: 30px;
  position: relative;
  margin-bottom: 20px;
}

.box_detail ul,
.filter_type ul li:last-child,
.secondary_nav ul,
.step .tab-content.checkout .nice-select,
footer ul.contacts li:last-child {
  margin-bottom: 0;
}

footer ul.contacts li i {
  position: absolute;
  top: 0;
  left: 0;
  color: #004dda;
  line-height: 1;
  font-size: 1.125rem;
}

#newsletter .loader {
  position: absolute;
  right: -20px;
  top: 11px;
  color: #444;
  font-size: 0.75rem;
}

#newsletter .form-group,
#sign-in-dialog .form-group,
.hero_single,
.jarallax,
.styled-select,
ul#additional_links li:after {
  position: relative;
}

#newsletter h6 {
  margin: 15px 0;
}

#newsletter input[type="email"] {
  border: 0;
  height: 40px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  padding-left: 15px;
  font-size: 0.875rem;
  background-color: rgba(0, 0, 0, 0.06);
}

#newsletter input[type="email"]:focus {
  border: 0;
  box-shadow: none;
}

#newsletter input[type="submit"] {
  position: absolute;
  right: 0;
  color: #fff;
  font-size: 0.8125rem;
  font-weight: 600;
  top: 0;
  border: 0;
  padding: 0 12px;
  height: 40px;
  line-height: 42px;
  cursor: pointer;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -ms-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  background-color: #004dda;
  transition: 0.3s ease-in-out;
  outline: 0;
}

.follow_us {
  animation-delay: 1.1s;
  -webkit-animation-delay: 1.1s;
  -moz-animation-delay: 1.1s;
}

.follow_us h5 {
  font-size: 1rem;
  margin-top: 35px;
}

.follow_us ul li {
  display: inline-block;
  margin-right: 5px;
  font-size: 1.25rem;
}

.follow_us ul li a i {
  color: #555;
  opacity: 0.6;
}

ul#footer-selector {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul#footer-selector li {
  float: left;
  margin-right: 10px;
}

ul#additional_links {
  margin: 0;
  padding: 8px 0 0;
  color: #555;
  font-size: 0.8125rem;
  float: right;
}

.box_list ul li,
.category_filter .container_radio,
.strip ul li,
ul#additional_links li {
  display: inline-block;
  margin-right: 15px;
}

ul#additional_links li:first-child {
  margin-right: 20px;
}

ul#additional_links li:last-child:after {
  content: "";
}

ul#additional_links li span {
  /* color: #555;
    opacity: .8 */
  color: #fff;
  font-size: 14px;
}

ul#additional_links li a {
  color: #555;
  opacity: 0.8;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

#carousel .owl-item.active.center .item .title h4,
#carousel .owl-item.active.center .item .views,
#reccomended .owl-item.active.center .item .title h4,
#reccomended .owl-item.active.center .item .views,
.container_radio input:checked ~ .checkmark:after,
.grid-gallery ul li figure:focus figcaption,
.grid-gallery ul li figure:hover figcaption,
.leaflet-fade-anim .leaflet-map-pane .leaflet-popup,
.leaflet-fade-anim .leaflet-tile-loaded,
.opened,
a.box_cat_home:hover i,
ul#additional_links li a:hover {
  opacity: 1;
}

ul#additional_links li:after {
  content: "|";
  font-weight: 300;
  left: 10px;
}

.styled-select {
  width: 100%;
  overflow: hidden;
  /* height: 30px;
    line-height: 30px; */
  height: 34px;
  line-height: 34px;
  border: none;
  /* background-color: rgba(0, 0, 0, .05); */
  background-color: rgb(255 255 255);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding-right: 35px;
}

.box_contacts a,
.pac-item-query,
.styled-select#lang-selector {
  color: #555;
}

.styled-select#lang-selector:after {
  font-family: ElegantIcons;
  content: "\e0e2";
  color: #555;
  position: absolute;
  right: 10px;
  top: 0;
}

.styled-select#currency-selector:after {
  font-family: ElegantIcons;
  content: "\33";
  color: #555;
  position: absolute;
  right: 10px;
  top: 0;
}

.styled-select select {
  background: 0 0;
  /* width: 110%; */
  width: 100%;
  padding-left: 10px;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  height: 30px;
  /* color: #555; */
  color: #000;
  /* font-size: .75rem; */
  font-size: 0.79rem;
  margin: 0;
  font-weight: 500;
  -moz-appearance: window;
  -webkit-appearance: none;
  cursor: pointer;
  outline: 0;
}

.styled-select select:focus {
  color: #555;
  outline: 0;
  box-shadow: none;
}

.styled-select select::-ms-expand {
  display: none;
}

.hero_single {
  width: 100%;
  text-align: center;
  margin: 0;
  color: #fff;
}

.hero_single .wrapper {
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

#error_page,
.hero_single.short {
  height: 600px;
}

.hero_single .wrapper h3 {
  color: #fff;
  font-size: 3.25rem;
  margin: 0;
  text-transform: uppercase;
  font-weight: 700;
}

@media (max-width: 767px) {
  .main_title_2 {
    margin-bottom: 10px;
  }

  .main_title_2 h2 {
    font-size: 1.625rem;
  }

  .main_title_2 p {
    font-size: 1.125rem;
  }

  .main_title_3 {
    border-left: none;
    padding-left: 0;
  }

  .main_title_3 span {
    display: none;
  }

  .hero_single .wrapper h3,
  .main_title_3 h2,
  .main_title_3 h3 {
    font-size: 1.3125rem;
  }

  .main_title_3 p {
    font-size: 0.875rem;
  }

  .main_title_3 a {
    top: -10px;
  }

  .btn_home_align {
    text-align: center;
    margin-bottom: 15px;
  }

  a.btn_filt,
  a.btn_filt_map {
    text-indent: -999px;
    display: block;
    width: 32px;
    height: 32px;
    padding: 0;
  }

  a.btn_filt:before,
  a.btn_filt_map:before {
    text-indent: 1px;
    position: absolute;
    left: 3px;
    top: 4px;
    font-size: 1.3125rem;
    font-weight: 400;
  }

  header.header {
    /* padding: 15px 15px 5px */
    padding: 15px 20px;
  }
}

.hero_single .wrapper p {
  font-weight: 300;
  margin: 10px 0 0;
  padding: 0 20%;
  font-size: 1.5rem;
  line-height: 1.4;
}

.hero_single.version_2 {
  height: 620px;
  /* background: url(../images/home_section_1.jpg) center center/cover no-repeat
    #222; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.call_section.pattern .wrapper,
.hero_single.jarallax .wrapper,
.hero_single.version_2 .wrapper,
.hero_single.version_3 .wrapper {
  background-color: rgba(0, 0, 0, 0.6);
}

.hero_single.version_2 .wrapper h3,
.hero_single.version_4 .wrapper h3 {
  font-size: 2.625rem;
  text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
}

@media (max-width: 767px) {
  .hero_single .wrapper p {
    padding: 0;
    font-size: 1.125rem;
  }

  .hero_single.version_2 .wrapper h3 {
    font-size: 1.625rem;
    margin-top: 30px;
  }
}

.hero_single.version_2 .wrapper p,
.hero_single.version_4 .wrapper p {
  font-weight: 400;
  margin: 5px 0 20px;
  padding: 0;
  font-size: 1.3125rem;
  text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
}

.hero_single.version_3 {
  height: 450px;
  /* background: url(../images/home_section_1.jpg) center center/cover no-repeat
    #222; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.hero_single.version_4 {
  height: 620px;
  background: url(../fonts/pattern_1.svg) center bottom repeat-x #004dda;
}

.hero_single.version_4 .wrapper {
  background: rgba(0, 0, 0, 0.6);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.6), transparent);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), transparent);
}

@media (max-width: 767px) {
  .hero_single.version_2 .wrapper p,
  .hero_single.version_4 .wrapper p {
    padding: 0;
    font-size: 0.875rem;
  }

  .hero_single.version_4 .wrapper h3 {
    font-size: 1.625rem;
    margin-top: 30px;
  }
}

.hero_single.version_4 .wrapper input[type="submit"]:hover,
.hero_single.version_5 .wrapper input[type="submit"]:hover,
.search_bar_error input[type="submit"]:hover,
.search_mob_wp input[type="submit"]:hover {
  background-color: #32a067;
  color: #fff;
}

.hero_single.version_5 {
  height: 640px;
  background: url(../fonts/pattern_2.svg) center center fixed #004dda;
}

.hero_single.version_5 .wrapper {
  background: rgba(0, 0, 0, 0.6);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.6), transparent);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), transparent);
  text-align: left;
}

.hero_single.version_5 .wrapper h3 {
  font-size: 2.25rem;
  text-shadow: none;
}

@media (max-width: 767px) {
  .hero_single.version_5 {
    height: 550px;
  }

  .hero_single.version_5 .wrapper h3 {
    font-size: 1.625rem;
    margin-top: 20px;
  }
}

.hero_single.version_5 .wrapper p {
  font-weight: 400;
  margin: 5px 0 20px;
  padding: 0;
  font-size: 1.3125rem;
  text-shadow: none;
}

.hero_single.version_5 .wrapper .custom-search-input-2 {
  background: 0 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.add_bottom_10,
.box_account .form_container .form-group,
.custom-search-input-2.inner-2 .form-group,
.hero_single.version_5 .wrapper .form-group,
.leaflet-bottom .leaflet-control,
.search_mob_wp .form-control,
.step .tab-content.checkout .form-group,
.step .tab-content.checkout .social_bt {
  margin-bottom: 10px;
}

.hero_single.version_5 .wrapper input[type="submit"] {
  color: #222;
  margin-top: 10px;
  background-color: #ffc107;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.hero_kenburns,
.hero_single.jarallax {
  width: 100%;
  position: relative;
  text-align: center;
  margin: 0;
  color: #fff;
  height: 620px;
  background-color: #004dda;
}

.hero_kenburns .wrapper,
.hero_single.jarallax .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
}

.hero_kenburns .wrapper h3,
.hero_single.jarallax .wrapper h3 {
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  font-size: 2.625rem;
  text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
}

.hero_kenburns .wrapper p,
.hero_single.jarallax .wrapper p {
  font-weight: 400;
  margin: 5px 0 20px;
  padding: 0;
  font-size: 1.5rem;
  text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
}

#custom-search-input input[type="submit"],
.custom-search-input-2 input[type="submit"] {
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  padding: 0 25px;
  height: 50px;
  background-color: #004dda;
  color: #fff;
  cursor: pointer;
  outline: 0;
}

.jarallax {
  z-index: 0;
}

.jarallax > .jarallax-img {
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

#custom-search-input {
  padding: 0;
  width: 600px;
  margin: 20px auto 0;
  position: relative;
}

#custom-search-input .search-query {
  width: 100%;
  height: 50px;
  padding-left: 20px;
  border: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  font-size: 1rem;
  color: #333;
}

#results.map_view,
#results_map_view {
  padding: 10px 0;
  margin: 0 -15px;
}

#custom-search-input input[type="submit"] {
  position: absolute;
  transition: 0.3s ease-in-out;
  right: -1px;
  font-weight: 600;
  font-size: 0.875rem;
  top: 0;
  border: 0;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -ms-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}

.custom-search-input-2 {
  background-color: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  margin-top: 10px;
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
}

.custom-search-input-2 input {
  border: 0;
  height: 50px;
  padding-left: 15px;
  border-right: 1px solid #d2d8dd;
  font-weight: 500;
}

.box_list ul,
.strip ul,
a.box_cat_home ul {
  border-top: 1px solid #ededed;
}

.custom-search-input-2 input:focus,
.custom-search-input-2.inner input:focus {
  box-shadow: none;
  border-right: 1px solid #d2d8dd;
}

#other_addr_c,
.booking .form-select-form select,
.booking select,
.custom-search-input-2 select,
.form-select-form select,
.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle,
.no-csspointerevents .nice-select .list,
.secondary_nav ul li:last-child {
  display: none;
}

.booking .nice-select .current,
.custom-search-input-2 .nice-select .current {
  font-weight: 500;
  color: #6f787f;
}

.custom-search-input-2 .form-group,
.filters_listing ul,
.filters_listing.version_2 ul {
  margin: 0;
}

.custom-search-input-2 i {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  font-size: 1.125rem;
  position: absolute;
  background-color: #fff;
  line-height: 50px;
  top: 1px;
  right: 1px;
  padding-right: 15px;
  display: block;
  width: 20px;
  box-sizing: content-box;
  height: 48px;
  z-index: 9;
  color: #999;
}

.custom-search-input-2 input[type="submit"] {
  transition: 0.3s ease-in-out;
  font-weight: 600;
  font-size: 0.875rem;
  border: 0;
  width: 100%;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -ms-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  margin-right: -1px;
}

.custom-search-input-2.inner input[type="submit"],
a.grid_item figure img {
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
}

#filters h6,
#hero_video,
#results h4,
#results_map_view h4,
.accordion_2 .card-header h5,
.main_categories ul li a h3,
a#filters_col_bt,
a.grid_item.small .info h3 {
  font-size: 1rem;
}

.custom-search-input-2.map_view {
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.custom-search-input-2.map_view input,
.pagination li:first-child,
.step .nav-tabs .nav-link,
.step.first h3:before,
.step.last h3:after,
input.qtyminus:focus,
input.qtyplus:focus {
  border: none;
}

.custom-search-input-2.map_view input[type="submit"] {
  margin: 20px 0 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.custom-search-input-2.inner {
  margin-top: 0;
  position: relative;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.custom-search-input-2.inner input {
  border: 0;
  height: 40px;
  padding-left: 15px;
  border-right: 1px solid #d2d8dd;
  font-weight: 500;
}

.custom-search-input-2.inner .nice-select {
  height: 40px;
  line-height: 38px;
}

.custom-search-input-2.inner i {
  height: 38px;
  line-height: 38px;
  padding-right: 10px;
}

.custom-search-input-2.inner input[type="submit"] {
  position: absolute;
  transition: 0.3s ease-in-out;
  width: inherit;
  right: -1px;
  color: #fff;
  font-weight: 600;
  font-size: 0.875rem;
  top: 0;
  border: 0;
  height: 40px;
  cursor: pointer;
  outline: 0;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -ms-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  text-indent: -999px;
  background: url(../fonts/search.svg) center center no-repeat #ffc107;
}

.custom-search-input-2.inner input[type="submit"]:hover,
.reviews-container .progress-bar {
  background-color: #32a067;
}

.custom-search-input-2.inner-2 {
  margin: 0 0 20px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: 0 0;
}

.custom-search-input-2.inner-2 .nice-select,
.custom-search-input-2.inner-2 input {
  border: 1px solid #ededed;
}

.custom-search-input-2.inner-2 input[type="submit"],
.main_categories ul {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
}

.custom-search-input-2.inner-2 input[type="submit"] {
  border-radius: 3px;
  margin-top: 10px;
}

.custom-search-input-2.inner-2 i {
  padding-right: 10px;
  line-height: 48px;
  height: 48px;
  top: 1px;
}

ul.counter {
  margin: 30px 0 0;
  padding: 0;
  text-align: center;
}

ul.counter li {
  padding: 0 10px;
  display: inline-block;
  font-size: 1.125rem;
  border-right: 1px solid #fff;
}

ul.counter li:last-child {
  border-right: none;
  text-align: left;
}

.main_categories {
  /* margin-top: -30px; */
  margin-top: 0;
  /* margin-bottom: 25px; */
  margin-bottom: 0;
  /* background: linear-gradient(360deg, rgb(237 235 235), transparent); */
  width: 100%;
  position: relative;
  z-index: 99;
}

.main_categories ul {
  background-color: #fff;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 15px 0;
  width: 90%;
  margin: auto;
}

a.grid_item,
a.grid_item figure {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  overflow: hidden;
}

.strip,
a.box_cat_home {
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
}

.main_categories ul li {
  display: inline-block;
  width: 20%;
  float: left;
  border-right: 1px solid #d2d8dd;
}

.main_categories ul li:last-child {
  border-right: none;
}

.container_check input:checked ~ .checkmark:after,
.leaflet-control-layers label,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.main_categories ul li a,
.no-csspointerevents .nice-select.open .list,
a.btn_search_mobile.map_view {
  display: block;
}

.main_categories ul li a i {
  font-size: 1.625rem;
  display: inline-block;
}

.header-video {
  position: relative;
  overflow: hidden;
  background: #000;
  height: 600px !important;
}

@media (max-width: 991px) {
  .btn_add,
  a.btn_add {
    display: none;
  }

  header {
    padding: 15px 20px;
  }

  header.header.sticky {
    /* padding: 15px 15px 5px */
    padding: 15px 20px;
  }

  header.header_in {
    padding: 12px 0 5px;
  }

  header.header_in ul#top_menu {
    position: absolute;
    right: 15px;
    float: none;
  }

  header.header_in #logo a {
    z-index: 9;
    position: relative;
  }

  .sub_header_in.sticky_header {
    margin-top: 48px;
  }

  ul#top_menu {
    margin: -2px 0 0 10px;
  }

  ul#top_menu li {
    margin: 2px 0 0 10px;
  }

  ul#top_menu li a.login,
  ul#top_menu li a.wishlist_bt_top {
    top: 2px;
  }

  #logo {
    float: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
  }

  #logo img {
    width: auto;
    height: 28px;
    margin: 12px 0 0;
  }

  ul#additional_links {
    float: none;
    margin-top: 10px;
  }

  .custom-search-input-2 input[type="submit"],
  .hero_single.version_4 .wrapper input[type="submit"] {
    margin: 20px 0 0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }

  .hero_single.version_5 .wrapper input[type="submit"] {
    margin: 20px 0 0;
  }

  #custom-search-input {
    width: auto;
  }

  .custom-search-input-2 {
    background: 0 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .custom-search-input-2 input,
  .custom-search-input-2.inner input {
    border: none;
  }

  .custom-search-input-2 input:focus,
  .custom-search-input-2.inner input:focus {
    border-right: none;
  }

  .custom-search-input-2 .form-group {
    margin-bottom: 5px;
  }

  .custom-search-input-2 i {
    padding-right: 10px;
  }

  .custom-search-input-2.inner {
    display: none;
    margin: 0 0 20px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .header-video {
    height: 620px !important;
  }
}

#hero_video {
  position: relative;
  background-size: cover;
  color: #fff;
  width: 100%;
  height: 100%;
  z-index: 99;
  text-align: center;
  display: table;
}

#hero_video .wrapper {
  display: table-cell;
  vertical-align: middle;
}

#hero_video .wrapper h3 {
  color: #fff;
  font-size: 2.625rem;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0;
  text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
}

@media (max-width: 767px) {
  .hero_single.version_5 .wrapper p {
    padding: 0;
    font-size: 0.875rem;
  }

  .hero_kenburns .wrapper h3,
  .hero_single.jarallax .wrapper h3 {
    font-size: 1.625rem;
    margin-top: 30px;
  }

  .hero_kenburns .wrapper p,
  .hero_single.jarallax .wrapper p {
    padding: 0;
    font-size: 1rem;
  }

  .main_categories ul li a h3,
  ul.counter {
    display: none;
  }

  .main_categories ul {
    width: 100%;
  }

  #hero_video .wrapper h3 {
    font-size: 1.625rem;
    margin-top: 60px;
  }
}

#hero_video .wrapper p {
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 1.4;
  text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
}

.header-video video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.6;
  width: 100%;
}

#carousel .item a,
#results,
#results_map_view,
.filters_listing,
.score span,
.strip,
.strip figure,
a.box_cat_home,
a.box_news,
a.grid_item figure {
  position: relative;
}

#hero_video iframe,
#map_right_listing {
  height: 100%;
  width: 100%;
}

.header-video--media,
.teaser-video {
  width: 100%;
  height: auto;
}

@media (max-width: 1024px) {
  #hero_video {
    /* background: url(../images/home_section_1.jpg) 0 0 / cover #ccc; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }

  #hero_video > div {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .header-video {
    background: 0 0;
  }
}

a.grid_item {
  display: block;
  margin-bottom: 30px;
  border-radius: 3px;
  height: 200px;
}

@media (max-width: 991px) {
  a.grid_item {
    height: 180px;
  }
}

@media (max-width: 767px) {
  #hero_video .wrapper p {
    padding: 0;
    font-size: 0.875rem;
  }

  a.grid_item {
    height: 150px;
  }
}

a.grid_item .info {
  position: absolute;
  width: 100%;
  z-index: 9;
  display: block;
  padding: 25px 25px 10px;
  color: #fff;
  left: 0;
  bottom: 0;
  background: 0 0;
  background: -webkit-linear-gradient(top, transparent, #000);
  background: linear-gradient(to bottom, transparent, #000);
  box-sizing: border-box;
}

a.grid_item .info small {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 0.6875rem;
  background-color: #004dda;
  line-height: 1;
  padding: 3px 5px 2px;
}

a.grid_item .info h3 {
  color: #fff;
  font-size: 1.3125rem;
}

a.grid_item .info p {
  color: #fff;
  margin-bottom: 0;
  font-size: 0.9375rem;
}

a.grid_item figure {
  border-radius: 3px;
  width: 100%;
  height: 100%;
}

a.box_cat_home,
a.box_news figure {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
}

@media (max-width: 767px) {
  a.grid_item .info {
    padding: 15px 15px 0;
  }

  a.grid_item figure {
    height: 150px;
  }

  a.grid_item figure img {
    width: 100%;
  }
}

a.grid_item figure img {
  transition: 0.3s ease-in-out;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}

.box_list figure a:hover img,
.strip figure a:hover img,
.strip.list_view figure a:hover img,
.strip.map_view figure a:hover img,
a.grid_item:hover figure img {
  -webkit-transform: translate(-50%, -50%) scale(1.1);
  -moz-transform: translate(-50%, -50%) scale(1.1);
  -ms-transform: translate(-50%, -50%) scale(1.1);
  -o-transform: translate(-50%, -50%) scale(1.1);
  transform: translate(-50%, -50%) scale(1.1);
}

a.grid_item.small {
  height: 170px;
}

@media (max-width: 1199px) {
  a.grid_item.small {
    height: 130px;
  }
}

@media (max-width: 991px) {
  a.grid_item.small {
    height: 180px;
  }
}

@media (max-width: 767px) {
  a.grid_item.small {
    height: 150px;
  }

  a.grid_item.small figure {
    height: 150px !important;
  }
}

a.grid_item.small .info {
  padding: 35px 15px 0;
}

a.grid_item.small figure img {
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  width: 100%;
}

#carousel,
#reccomended {
  margin-top: 40px;
}

#reccomended .item {
  margin: 0 15px;
}

#carousel .owl-item,
#reccomended .owl-item {
  backface-visibility: hidden;
  /* opacity: .5;
    transform: scale(.85);
    -webkit-transform: translateZ(0) scale(.85, .85); */
  transform: scale(1);
  -webkit-transform: translateZ(0) scale(1, 1);
  transition: 0.3s ease-in-out;
  overflow: hidden;
}

.app_icons,
.app_icons a img {
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
}

#carousel .owl-item.active.center,
#reccomended .owl-item.active.center {
  opacity: 1;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
  transform: scale(1);
}

.strip figure a img,
a.box_news figure img {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  left: 50%;
}

.owl-theme .owl-dots {
  margin-top: 25px !important;
  outline: 0;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #004dda !important;
  outline: 0 !important;
}

.owl-theme .owl-dots .owl-dot span {
  width: 13px !important;
  height: 13px !important;
}

.app_icons {
  margin-bottom: 10px;
  transition: 0.3s ease-in-out;
}

.app_icons a img {
  transition: 0.3s ease-in-out;
}

a.box_news figure img,
a.box_news:hover h4 {
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
}

.app_icons a img:hover {
  -webkit-transform: translateY(-5px);
  -moz-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -o-transform: translateY(-5px);
  transform: translateY(-5px);
}

a.box_news {
  display: block;
  padding-left: 230px;
  color: #555;
  margin-bottom: 30px;
  min-height: 150px;
}

a.box_news figure {
  width: 200px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 3px;
}

a.box_news figure img {
  width: 250px;
  height: auto;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(1.1);
  -moz-transform: translate(-50%, -50%) scale(1.1);
  -ms-transform: translate(-50%, -50%) scale(1.1);
  -o-transform: translate(-50%, -50%) scale(1.1);
  transform: translate(-50%, -50%) scale(1.1);
  transition: 0.3s ease-in-out;
  backface-visibility: hidden;
}

.strip figure a img,
a.box_news:hover img {
  -webkit-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
}

a.box_news figure figcaption {
  background-color: #ffc107;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  display: inline-block;
  color: #fff;
  width: 60px;
  height: 60px;
  padding: 15px;
  line-height: 1;
}

a.box_news figure figcaption strong {
  display: block;
  font-size: 1.3125rem;
}

a.box_news h4 {
  font-size: 1.125rem;
  margin-bottom: 10px;
}

a.box_news:hover img {
  transform: translate(-50%, -50%) scale(1);
}

a.box_news:hover h4 {
  color: #004dda;
  transition: 0.3s ease-in-out;
}

a.box_news ul {
  margin: 0;
  padding: 10px 0 0;
}

a.box_news ul li {
  display: inline-block;
  font-weight: 600;
  text-transform: uppercase;
  color: #999;
  font-size: 0.75rem;
  margin-right: 5px;
}

a.box_news ul li:last-child {
  margin-right: 0;
}

a.box_news ul li:last-child:after {
  content: "";
  margin: 0;
}

a.box_news ul li:after {
  content: "-";
  margin: 0 0 0 10px;
}

a.box_cat_home {
  background-color: #fff;
  display: block;
  padding: 25px 15px;
  border-radius: 3px;
  text-align: center;
  margin-bottom: 25px;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  color: #999;
}

.strip.map_view a.wish_bt,
a.box_feat {
  color: #555;
}

a.box_cat_home i {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 1.125rem;
  color: #0054a6;
  opacity: 0;
  -moz-transition: 0.6s ease-in-out;
  -o-transition: 0.6s ease-in-out;
  -webkit-transition: 0.6s ease-in-out;
  -ms-transition: 0.6s ease-in-out;
  transition: 0.6s ease-in-out;
}

a.box_cat_home h3 {
  font-size: 1.125rem;
  margin-top: 10px;
}

a.box_cat_home:hover {
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  -webkit-transform: translateY(-5px);
  -moz-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -o-transform: translateY(-5px);
  transform: translateY(-5px);
}

a.box_cat_home ul {
  margin: 20px -15px -25px;
  padding: 10px;
  text-align: center;
}

.box_list figure small,
.strip figure small {
  padding: 7px 10px 4px;
  background-color: rgba(0, 0, 0, 0.6);
  text-transform: uppercase;
}

a.box_cat_home ul li strong {
  margin-right: 5px;
  font-weight: 600;
  color: #777;
}

a.box_feat {
  text-align: center;
  background: #fff;
  padding: 40px 30px 30px;
  display: block;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  -webkit-box-shadow: 0 3px 0 0 #f0f2f4;
  -moz-box-shadow: 0 3px 0 0 #f0f2f4;
  box-shadow: 0 3px 0 0 #f0f2f4;
  margin-bottom: 30px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  overflow: hidden;
}

a.box_feat:hover {
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  transform: translateY(-10px);
  -webkit-box-shadow: 0 3px 0 0 #ebedef;
  -moz-box-shadow: 0 3px 0 0 #ebedef;
  box-shadow: 0 3px 0 0 #ebedef;
}

a.box_feat i {
  font-size: 4.375rem;
  color: #32a067;
  margin-bottom: 10px;
}

.box_faq h4,
a.box_feat h3,
a.box_topic h3,
section#description h4,
section#reviews h4 {
  font-size: 1.125rem;
}

#carousel .item .title {
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;
  z-index: 9;
}

#carousel .item .title h4 {
  background-color: #0054a6;
  display: inline-block;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  -ms-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
  color: #fff;
  padding: 10px 15px;
  margin: 0;
  font-size: 1rem;
  opacity: 0;
}

#carousel .item .title h4 em {
  display: block;
  font-size: 0.8125rem;
  font-weight: 300;
  margin-top: 3px;
}

#carousel .item a {
  display: block;
}

#results {
  padding: 20px 0;
  color: #fff;
  background: #004dda;
  z-index: 999 !important;
}

#results.is_stuck {
  z-index: 99;
  padding: 10px 0;
}

#results h4 {
  color: #fff;
  margin: 12px 0 0;
  padding: 0;
  line-height: 1;
}

#results.map_view h4 {
  margin: 3px 0 0;
}

#results_map_view {
  color: #fff;
  background: #004dda;
}

#results_map_view h4 {
  color: #fff;
  margin: 3px 0 0;
  padding: 0;
  line-height: 1;
}

#filters h6,
.add_bottom_15,
.distance,
.strip .wrapper p,
a.boxed_list i {
  margin-bottom: 15px;
}

#filters {
  background-color: #fff;
}

#filters ul li small {
  font-weight: 600;
  float: right;
  position: relative;
  top: 4px;
}

.filters_listing.version_3,
.map_view#filters {
  margin: 0 -15px;
}

.distance span {
  font-weight: 600;
  color: #004dda;
}

.rangeslider__handle {
  border: 2px solid #004dda !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
}

.strip,
.tools_map {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
}

.rangeslider__fill {
  background: #004dda !important;
}

.box_list,
.strip {
  background-color: #fff;
  display: block;
}

.filters_listing {
  padding: 10px 0 5px;
  background: #fff;
  border-bottom: 1px solid #ededed;
  z-index: 99 !important;
}

.filters_listing ul li,
.filters_listing.version_2 ul li,
.filters_listing.version_3 ul li,
.strip.list_view .score,
.strip.map_view .score,
ul.hotel_facilities li img {
  margin-right: 10px;
}

.filters_listing ul li:nth-child(2) {
  float: left;
  margin-right: 0;
}

.filters_listing ul li:nth-child(3),
.filters_listing.version_2 ul li:nth-child(2),
.filters_listing.version_3 ul li:nth-child(2) {
  float: right;
  margin-right: 0;
}

.filters_listing ul li:nth-child(4),
.nice-select.right,
ul.menu_list li h6 span {
  float: right;
}

.filters_listing.version_2 ul li:nth-child(3),
.leaflet-right .leaflet-control {
  float: right;
  margin-right: 10px;
}

.filters_listing.version_3 ul {
  margin: 2px 0 0;
}

.tools_map {
  border-radius: 3px;
  font-size: 1.4375rem;
}

.tools_map a {
  color: rgba(0, 0, 0, 0.5);
  margin: 0 3px;
}

.box_detail ul li i,
.score span {
  margin-right: 8px;
}

.tools_map i {
  font-weight: 500;
  line-height: 1;
}

.score strong {
  background-color: #32a067;
  color: #fff;
  line-height: 1;
  -webkit-border-radius: 5px 5px 5px 0;
  -moz-border-radius: 5px 5px 5px 0;
  -ms-border-radius: 5px 5px 5px 0;
  border-radius: 5px 5px 5px 0;
  padding: 10px;
  display: inline-block;
}

.score span {
  display: inline-block;
  top: 7px;
  font-size: 0.75rem;
  text-align: right;
  line-height: 1.1;
  font-weight: 500;
}

.cat_star i,
.rating i {
  margin-right: 2px;
}

.score span em {
  display: block;
  font-weight: 400;
  font-size: 0.6875rem;
}

.strip {
  margin-bottom: 30px;
  border-radius: 3px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
}

.strip a.wish_bt {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 9px 10px;
  display: inline-block;
  color: #fff;
  line-height: 1;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.strip a.wish_bt:after {
  font-family: ElegantIcons;
  content: "\e012";
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

.strip a.wish_bt.liked:after {
  content: "\e078";
  color: #ffc107;
}

.strip a.wish_bt:hover.liked:after {
  color: #ffc107;
}

.strip a.wish_bt:hover:after {
  content: "\e078";
  color: #fff;
}

.strip.map_view a.wish_bt.liked:after,
a.address:hover {
  color: #004dda;
}

.strip figure {
  margin-bottom: 0;
  overflow: hidden;
  height: 200px;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  -ms-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}

.strip figure small {
  position: absolute;
  left: 20px;
  top: 22px;
  color: #fff;
  font-weight: 600;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  line-height: 1;
}

.box_list figure .read_more,
.strip figure .read_more {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -12px;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  text-align: center;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  z-index: 2;
}

.strip figure a img,
.strip.list_view figure a img {
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
}

.box_list figure .read_more span,
.strip figure .read_more span {
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px;
  display: inline-block;
  color: #222;
  font-size: 0.75rem;
  padding: 5px 10px;
}

.box_list figure:hover .read_more,
.strip figure:hover .read_more {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.strip figure a img {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
  backface-visibility: hidden;
  width: 100%;
  transition: 0.3s ease-in-out;
}

.animated .box_1,
.box_list figure a img {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
}

.strip .wrapper {
  padding: 20px 25px 15px;
}

.strip .wrapper h3 {
  font-size: 1.25rem;
  margin: 0;
}

.strip .wrapper small {
  font-weight: 600;
  margin-bottom: 10px;
  display: inline-block;
  font-size: 0.8125rem;
}

.strip ul {
  padding: 20px 15px 17px 20px;
}

.box_list ul,
.strip.list_view ul {
  padding: 20px 15px 20px 30px;
  margin-bottom: 0;
}

.box_list ul li .score,
.strip ul li .score {
  margin-top: -10px;
}

.strip ul li:first-child {
  font-size: 0.8125rem;
  margin-top: -4px;
}

.strip ul li:first-child i {
  font-size: 1.25rem;
  position: relative;
  top: 4px;
}

.box_list ul li:last-child,
.strip ul li:last-child {
  margin-right: 0;
  float: right;
}

.strip.list_view {
  min-height: 290px;
}

.strip.list_view a.wish_bt {
  background-color: rgba(0, 0, 0, 0.1);
  color: #555;
  top: 25px;
  right: 25px;
}

.box_list a.wish_bt,
.strip.map_view a.wish_bt {
  right: 15px;
  top: 15px;
  background-color: rgba(0, 0, 0, 0.1);
}

.strip.list_view a.wish_bt:hover.liked:after {
  color: #004dda;
}

.strip.list_view figure {
  margin-bottom: 0;
  overflow: hidden;
  position: relative;
  min-height: 300px;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  -ms-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}

.strip.list_view figure small {
  right: 30px;
  top: 30px;
  left: inherit;
}

.strip.list_view figure a img {
  height: 360px;
  width: auto;
  max-width: none;
  transition: 0.3s ease-in-out;
}

.strip.list_view .wrapper {
  padding: 30px 30px 20px;
  min-height: 235px;
}

.strip.map_view {
  min-height: 120px;
  margin-bottom: 20px;
}

.strip.map_view a.wish_bt:hover.liked:after {
  color: #004dda;
}

.strip.map_view a.address:before {
  content: "\e081";
}

.strip.map_view figure {
  margin-bottom: 0;
  overflow: hidden;
  position: relative;
  min-height: inherit;
  height: 185px;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  -ms-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}

.strip.map_view figure small {
  right: 20px;
  top: 20px;
  left: inherit;
}

.strip.map_view figure a img {
  height: 210px;
  width: auto;
  max-width: none;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.strip.map_view .wrapper {
  padding: 20px 20px 0;
  min-height: 110px;
}

.strip.map_view .wrapper small {
  line-height: 1.3;
  margin-top: 5px;
}

.strip.map_view ul {
  padding: 20px 5px 15px 15px;
  margin-bottom: 0;
}

a.address {
  display: inline-block;
  font-weight: 500;
  color: #999;
  line-height: 1;
}

#error_page h2 i,
.cat_star i,
.grid-gallery ul li figcaption a:focus,
.grid-gallery ul li figcaption a:hover,
.rating .voted {
  color: #ffc107;
}

a.address:before {
  font-family: ElegantIcons;
  content: "\e01c";
  margin-right: 5px;
  display: inline-block;
}

.loc_closed,
.loc_open {
  position: relative;
  top: -2px;
  font-size: 0.6875rem;
  font-weight: 600;
  padding: 2px 8px;
  line-height: 1;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.loc_open {
  color: #32a067;
  border: 1px solid #32a067;
}

.loc_closed {
  color: #eb5c65;
  border: 1px solid #eb5c65;
}

.box_list a.wish_bt,
.box_list figure small {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  position: absolute;
  line-height: 1;
}

.rating {
  color: #ccc;
}

.box_list {
  position: relative;
  margin: 0 0 30px;
  min-height: 310px;
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
}

.layer,
.map-right,
.row-height {
  min-height: 100%;
}

.box_list a.wish_bt {
  z-index: 1;
  padding: 9px 10px;
  display: inline-block;
  color: #555;
  border-radius: 3px;
}

.box_list a.wish_bt:after {
  font-family: ElegantIcons;
  content: "\e030";
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

.box_list a.wish_bt.liked:after {
  content: "\e089";
  color: #004dda;
}

.box_list a.wish_bt:hover.liked:after {
  color: #004dda;
}

.box_list a.wish_bt:hover:after {
  content: "\e089";
  color: #999;
}

.box_list figure {
  margin-bottom: 0;
  overflow: hidden;
  position: relative;
  min-height: 310px;
}

.box_list figure small {
  right: 20px;
  top: 20px;
  color: #ccc;
  font-weight: 600;
  border-radius: 3px;
  z-index: 9;
}

.box_list figure a img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  backface-visibility: hidden;
  height: 350px;
  width: auto;
  max-width: none;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.box_list .wrapper {
  padding: 30px 30px 20px;
  min-height: 245px;
}

.box_list .wrapper h3 {
  font-size: 1.25rem;
  margin-top: 0;
}

.box_list small {
  text-transform: uppercase;
  color: #999;
  font-weight: 600;
}

#list_sidebar .box_list,
#list_sidebar .box_list figure {
  min-height: 330px;
}

#list_sidebar .box_list figure a img {
  height: 350px;
}

#list_sidebar .box_list .wrapper {
  min-height: 265px;
}

.layout_view {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: 4px 8px 3px;
}

.layout_view a {
  color: rgba(0, 0, 0, 0.5);
}

.layout_view a.active {
  color: rgba(0, 0, 0, 0.8);
}

#search_mobile a.side_panel:hover,
.hero_in a.btn_photos:hover,
.layout_view a:hover,
.leaflet-popup-content a,
.payments ul li a.info:hover,
.search_mob a:hover,
a.btn_infobox_detail:before:hover,
ul#cat_nav li a#active,
ul#cat_nav li a.active,
ul#cat_nav li a:hover {
  color: #004dda;
}

.switch-field {
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.08);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field input:checked + label {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.9);
}

.switch-field label {
  float: left;
  display: inline-block;
  min-width: 65px;
  /* color: rgba(0, 0, 0, .5); */
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
  font-size: 0.75rem;
  text-align: center;
  text-shadow: none;
  padding: 10px 8px 8px;
  line-height: 1;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  margin: 0;
}

.filter_type h6,
.step .tab-content.checkout {
  padding: 15px 0 0;
}

.switch-field label:first-of-type {
  border-left: 0;
}

.switch-field label:last-of-type {
  border-right: 0;
}

.switch-field label:hover {
  cursor: pointer;
  color: #004dda;
}

#filters_col {
  background-color: #fff;
  padding: 20px 20px 15px;
  margin-bottom: 25px;
  border: 1px solid #ededed;
}

#filters_col label {
  color: #333;
  font-weight: 400;
}

a#filters_col_bt {
  display: block;
  color: #333;
  position: relative;
  font-weight: 600;
}

a#filters_col_bt:before {
  font-family: ElegantIcons;
  content: "\66";
  font-size: 1.625rem;
  position: absolute;
  right: 0;
  top: 0;
  line-height: 1;
  font-weight: 400;
}

.filter_type h6 {
  border-top: 1px solid #ededed;
  margin: 15px 0;
  font-size: 0.8125rem;
}

.filter_type ul {
  list-style: none;
  padding: 0;
  margin: 0 0 15px;
}

.box_account,
.category_filter,
.detail_title_1,
a.boxed_list {
  margin-bottom: 25px;
}

.filter_type ul li small {
  float: right;
  position: relative;
  top: 4px;
}

.category_filter {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

a.boxed_list {
  text-align: center;
  padding: 30px;
  border: 1px solid #ededed;
  display: block;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

a.boxed_list i {
  font-size: 3.75rem;
}

a.boxed_list h4 {
  font-size: 1.125rem;
  color: #333;
}

a.boxed_list p {
  color: #777;
  margin-bottom: 0;
}

a.boxed_list:hover i {
  color: #32a067;
}

a.btn_search_mobile {
  background: url(../fonts/search.svg) center center no-repeat;
  width: 21px;
  height: 21px;
  float: right;
  display: none;
}

#search_mobile {
  overflow: auto;
  transform: translateX(105%);
  top: 0;
  right: 0;
  bottom: 0;
  width: 50%;
  height: 100%;
  position: fixed;
  background: url(../fonts/bg_aside.svg) center right/cover no-repeat #fff;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  padding: 60px 30px 30px;
  -webkit-box-shadow: 1px 0 5px 0 rgba(50, 50, 50, 0.55);
  -moz-box-shadow: 1px 0 5px 0 rgba(50, 50, 50, 0.55);
  box-shadow: 1px 0 5px 0 rgba(50, 50, 50, 0.55);
  -webkit-transition: 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-transition: 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  transition: 0.5s cubic-bezier(0.77, 0, 0.175, 1);
}

#search_mobile.show {
  transform: translateX(0);
}

#search_mobile input.form-control {
  border: 1px solid #d2d8dd;
  box-shadow: none;
}

#search_mobile .custom-search-input-2,
.leaflet-touch .leaflet-bar,
.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers {
  box-shadow: none;
}

#search_mobile .custom-search-input-2 input[type="submit"] {
  margin: 20px 0 0;
}

#search_mobile .nice-select {
  border: 1px solid #d2d8dd;
}

#search_mobile a.side_panel {
  font-size: 2rem;
  display: block;
  position: absolute;
  right: 10px;
  top: 0;
  color: #999;
}

.search_mob a {
  display: none;
  width: 22px;
  height: 24px;
  text-indent: -9999px;
  position: relative;
  color: #0054a6;
}

.search_mob a:before {
  font-family: themify;
  content: "\e610";
  text-indent: 0;
  position: absolute;
  left: 0;
  top: 0;
  line-height: 1;
  font-weight: 600;
  font-size: 1.3125rem;
}

.search_mob_wp {
  padding: 10px 0;
  display: none;
  width: 100%;
}

.search_mob_wp .nice-select {
  margin-bottom: 5px;
  border: none;
}

.layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-transition:
    transform 0.3s,
    opacity 0.3s,
    visibility 0s 0.3s;
  -moz-transition:
    transform 0.3s,
    opacity 0.3s,
    visibility 0s 0.3s;
  -ms-transition:
    transform 0.3s,
    opacity 0.3s,
    visibility 0s 0.3s;
  -o-transition:
    transform 0.3s,
    opacity 0.3s,
    visibility 0s 0.3s;
  transition:
    transform 0.3s,
    opacity 0.3s,
    visibility 0s 0.3s;
  opacity: 0;
}

.layer-is-visible {
  opacity: 1;
  visibility: visible;
  -webkit-transition:
    opacity 0.3s,
    transform 0.3s;
  -moz-transition:
    opacity 0.3s,
    transform 0.3s;
  -ms-transition:
    opacity 0.3s,
    transform 0.3s;
  -o-transition:
    opacity 0.3s,
    transform 0.3s;
  transition:
    opacity 0.3s,
    transform 0.3s;
}

.hero_in {
  width: 100%;
  height: 450px;
  position: relative;
  overflow: hidden;
}

.hero_in.hotels_detail,
.hero_in.restaurant_detail,
.hero_in.shop_detail {
  height: 550px;
}

.hero_in.shop_detail:before {
  background: url(../images/hero_in_shop_detail.jpg) center center/cover no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.hero_in.hotels_detail .wrapper,
.hero_in.restaurant_detail .wrapper,
.hero_in.shop_detail .wrapper {
  background-color: rgba(0, 0, 0, 0.2);
}

.hero_in.hotels_detail:before {
  background: url(../images/hero_in_hotels_detail.jpg) center center/cover no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.hero_in.restaurant_detail:before {
  background: url(../images/hero_in_restaurants_detail.jpg) center center/cover no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.hero_in:before {
  animation: 5s cubic-bezier(0, 0.5, 0, 1) 0.1s forwards pop-in;
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.full-height,
.hero_in .wrapper {
  height: 100%;
}

.hero_in a.btn_photos {
  position: absolute;
  left: 20px;
  bottom: 20px;
  background-color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  line-height: 1;
  padding: 10px 15px;
  color: #444;
  font-weight: 500;
}

.box_detail,
.step #forgot_pw {
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
}

@keyframes pop-in {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
}

.secondary_nav {
  background: #004dda;
  padding: 15px 0;
}

.secondary_nav.is_stuck {
  z-index: 99;
  width: 100% !important;
  left: 0;
}

.secondary_nav ul li {
  display: inline-block;
  margin-right: 20px;
  font-weight: 500;
  font-size: 1rem;
}

.secondary_nav ul li a {
  color: rgba(255, 255, 255, 0.5);
}

.secondary_nav ul li a:hover {
  color: #fff;
  opacity: 1;
}

#carousel_in .owl-dots {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
  z-index: 5;
}

.detail_title_1 h1 {
  font-size: 2rem;
  margin: 0;
}

.detail_title_1 ul {
  float: right;
  margin: 10px 0 0;
}

.detail_title_1 ul li {
  display: inline-block;
  margin-right: 20px;
  font-weight: 500;
}

section#description,
section#reviews {
  border-bottom: 3px solid #d2d8dd;
  margin-bottom: 30px;
}

section#description h2,
section#reviews h2 {
  font-size: 1.5rem;
}

#error_page p,
section#description h3,
section#reviews h3 {
  font-size: 1.3125rem;
}

.form-select-form .nice-select:hover,
section#description hr,
section#reviews hr {
  border-color: #d2d8dd;
}

.strip_booking.last,
section#reviews {
  border-bottom: none;
}

#review_summary {
  text-align: center;
  background-color: #32a067;
  color: #fff;
  padding: 20px 10px;
  -webkit-border-radius: 3px 3px 3px 0;
  -moz-border-radius: 3px 3px 3px 0;
  -ms-border-radius: 3px 3px 3px 0;
  border-radius: 3px 3px 3px 0;
}

.reviews-container .rev-content,
.reviews-container .rev-thumb {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
}

#review_summary strong {
  font-size: 2.625rem;
  display: block;
  line-height: 1;
}

#review_summary em {
  font-style: normal;
  font-weight: 500;
  display: block;
}

.reviews-container .progress {
  margin-bottom: 12px;
}

.reviews-container .review-box {
  position: relative;
  margin-bottom: 25px;
  padding-left: 100px;
  min-height: 100px;
}

.reviews-container .rev-thumb {
  position: absolute;
  left: 0;
  top: 0;
  width: 80px;
  height: 80px;
  background: #fff;
  border-radius: 3px;
  overflow: hidden;
}

.reviews-container .rev-thumb img {
  width: 80px;
  height: auto;
}

.reviews-container .rev-content {
  position: relative;
  padding: 25px 25px 1px;
  border-radius: 3px;
  background-color: #fff;
}

.booking .dropdown,
.box_detail {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  background-color: #fff;
}

.reviews-container .rev-info {
  font-size: 0.75rem;
  font-style: italic;
  color: #777;
  margin-bottom: 10px;
}

.box_detail {
  padding: 25px 25px 15px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  margin-bottom: 30px;
}

.box_detail figure a i,
.marker-cluster div {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
}

.box_detail .price {
  line-height: 1;
  border-bottom: 1px solid #ededed;
  margin: 0 -25px 25px;
  padding: 0 25px 15px;
}

.box_detail .price > span {
  font-size: 1.9375rem;
  font-weight: 600;
}

.box_detail .price > span > small {
  font-size: 0.6875rem;
  font-weight: 500;
}

.box_detail .price .score {
  float: right;
  margin-top: -5px;
}

.box_detail h3 {
  font-size: 1.25rem;
  margin: 25px 0 10px;
}

.box_detail figure {
  position: relative;
  background-color: #000;
}

.box_1 p,
.box_detail figure img,
.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.box_detail figure span {
  position: absolute;
  display: block;
  left: 0;
  bottom: 10px;
  text-align: center;
  width: 100%;
  color: #fff;
  font-weight: 600;
}

#qty_total,
.dropdown,
.dropdown a,
.opening,
.qty-buttons,
ul.menu_list li {
  position: relative;
}

.box_detail figure a i {
  background-color: rgba(0, 0, 0, 0.6);
  display: block;
  width: 60px;
  height: 60px;
  color: #fff;
  border-radius: 50%;
  line-height: 60px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
  font-size: 2.625rem;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  z-index: 9;
}

.box_detail figure:hover i {
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3);
}

@media screen and (min-width: 564px) {
  .daterangepicker {
    width: 500px !important;
  }

  .daterangepicker.single {
    width: auto !important;
  }
}

.booking input {
  font-weight: 500;
  height: 45px;
}

.booking input:focus,
.booking textarea:focus {
  box-shadow: none;
  border: 1px solid #d2d8dd;
}

.booking .dropdown {
  font-size: 0.875rem;
  border-radius: 3px;
  border: 1px solid #d2d8dd;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0 0 10px;
}

.booking .form-select-form .nice-select.open,
.booking .form-select-form .nice-select:active,
.booking .form-select-form .nice-select:focus {
  border-color: #d2d8dd;
  outline: 0;
  box-shadow: none;
}

.booking .form-group,
.box_account .form_container hr {
  margin: 0 0 10px;
}

.booking .form-group i {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  font-size: 1.125rem;
  position: absolute;
  background-color: #fff;
  height: 43px;
  top: 1px;
  right: 1px;
  padding-right: 10px;
  display: block;
  width: 20px;
  box-sizing: content-box;
  line-height: 43px;
  z-index: 9;
  color: #999;
}

.booking .form-group i.loader {
  background-color: transparent;
  color: #fff;
}

#sign-in-dialog .form-group i.icon_lock_alt,
.booking .form-group i.icon_lock_alt {
  top: 36px;
}

.booking .form-group i.ti-pencil {
  top: 1px;
  height: 20px;
}

.booking textarea {
  font-weight: 500;
  height: 100px;
  padding-right: 25px;
}

/* .dropdown {
  text-align: left;
  padding: 15px 10px 0 15px;
} */

.dropdown a {
  color: #727b82;
  font-weight: 500;
  transition: 0.3s;
  display: block;
}

.container_check .checkmark,
.dropdown a:after {
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
}

.dropdown a:after {
  font-family: ElegantIcons;
  content: "\33";
  font-size: 1.5rem;
  color: #999;
  font-weight: 500;
  transition: 0.3s ease-in-out;
  position: absolute;
  right: 0;
  top: -8px;
}

.dropdown.show a:after {
  transform: rotate(180deg);
}

.dropdown .dropdown-menu {
  font-size: 0.875rem;
  -moz-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  transition: opacity 0.5s;
  margin-top: 16px;
  background: #fff;
  border: 1px solid #d2d8dd;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  box-shadow: 0 12px 35px 2px rgba(0, 0, 0, 0.12);
  padding: 15px 15px 0;
  white-space: normal;
  width: 280px;
}

.dropdown .dropdown-menu input:focus {
  outline: 0 !important;
  border: none !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.ribbon span.open::after,
.ribbon span.open::before {
  content: "";
  top: 100%;
  z-index: -1;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #5d8108;
}

input.qtyminus,
input.qtyplus {
  top: 1px;
  text-indent: -9999px;
  box-shadow: none;
}

.dropdown-menu-content {
  display: flex;
  margin-bottom: 10px;
}

.dropdown-menu-content label {
  display: block;
  flex: 1;
  line-height: 40px;
  color: #727b82;
}

.qty-buttons {
  height: 38px;
  display: inline-block;
  width: 140px;
}

input.qty {
  width: 100%;
  text-align: center;
  border: none !important;
  color: #495057;
  font-size: 1rem;
  height: 40px;
}

input.qtyminus,
input.qtyplus {
  position: absolute;
  width: 36px;
  height: 36px;
  border: 0;
  outline: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px;
}

input.qtyplus {
  background: url(../fonts/plus.svg) center center no-repeat #f2f2f2;
  right: 3px;
}

input.qtyminus {
  background: url(../fonts/minus.svg) center center no-repeat #f2f2f2;
  left: 3px;
}

#qty_total {
  display: inline-block;
  font-size: 11px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  top: 0;
  left: 2px;
  height: 18px;
  width: 18px;
  background-color: #66676b;
  border-radius: 50%;
  color: #fff;
}

.rotate-x {
  animation-duration: 0.5s;
  animation-name: rotate-x;
}

/* @keyframes rotate-x {
    from {
        transform: rotateY(0)
    }

    to {
        transform: rotateY(360deg)
    }
} */

ul.hotel_facilities {
  list-style: none;
  margin: 0;
  padding: 0;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}

ul.hotel_facilities li {
  margin-bottom: 10px;
  display: inline-block;
  width: 100%;
}

.room_type {
  padding: 10px 0 25px;
}

.accordion_2 .card-body,
.room_type.last {
  padding-bottom: 0;
}

.opening {
  background-color: rgba(0, 77, 218, 0.05);
  padding: 25px 25px 25px 80px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

#confirm,
.content-left {
  background-color: #f8f8f8;
}

.opening i {
  position: absolute;
  left: 25px;
  top: 25px;
  font-size: 2.25rem;
  color: #555;
}

.add_top_10,
.leaflet-top .leaflet-control,
.opening h4 {
  margin-top: 10px;
}

#cookie-bar p,
.opening ul,
ul.menu_list {
  padding: 0;
  margin: 0;
}

.opening ul li span {
  display: inline-block;
  float: right;
}

ul.menu_list {
  list-style: none;
}

ul.menu_list li {
  padding: 5px 0 25px 120px;
  clear: both;
  font-size: 0.8125rem;
}

ul.menu_list li h6 {
  font-size: 0.875rem;
  border-bottom: 1px dashed #ddd;
  padding-bottom: 5px;
}

ul.menu_list li .thumb {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  border: 1px solid #ddd;
  padding: 5px;
}

#pass-info,
a.social_bt {
  -webkit-border-radius: 3px;
  -ms-border-radius: 3px;
}

ul.menu_list li .thumb img {
  width: 88px;
  height: auto;
  text-align: center;
}

.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}

.ribbon span {
  font-size: 10px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  position: absolute;
  top: 19px;
  right: -21px;
}

.ribbon span.open {
  background: #79a70a;
}

.ribbon span.open::before {
  position: absolute;
  left: 0;
  border-left: 3px solid #5d8108;
  border-right: 3px solid transparent;
}

.ribbon span.open::after {
  position: absolute;
  right: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid #5d8108;
}

.ribbon span.closed::after,
.ribbon span.closed::before {
  top: 100%;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #7d2528;
  position: absolute;
  content: "";
  z-index: -1;
}

.ribbon span.closed {
  background: #ca3b41;
}

.ribbon span.closed::before {
  left: 0;
  border-left: 3px solid #7d2528;
  border-right: 3px solid transparent;
}

.ribbon span.closed::after {
  right: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid #7d2528;
}

.row-height {
  height: 100%;
  padding-top: 58px;
}

.map-right {
  height: 100%;
  padding: 0;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
}

.content-left {
  height: 100%;
  min-height: 100%;
  overflow-y: scroll;
}

#login_bg,
#register_bg {
  background: url(../images/access_bg.jpg) center center/cover no-repeat fixed #444;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  min-height: 100vh;
  width: 100%;
}

#register_bg {
  background: url(../images/access_bg.jpg) center center/cover no-repeat fixed #444;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

#login figure,
#register figure {
  text-align: center;
  border-bottom: 1px solid #ededed;
  margin: -45px -60px 25px;
  padding: 20px 60px 25px;
}

#login aside,
#register aside {
  width: 430px;
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.5);
  padding: 45px 60px 60px;
  margin-left: -215px;
  position: absolute;
  left: 50%;
  top: 0;
  overflow-y: auto;
  background-color: #fff;
  min-height: 100vh;
}

#login aside form,
#register aside form {
  margin-bottom: 60px;
  display: block;
}

#login aside form .form-group input,
#register aside form .form-group input,
#sign-in-dialog .form-group input.form-control,
.more_padding_left {
  padding-left: 40px;
}

.container_check,
.container_radio {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
  -webkit-user-select: none;
  cursor: pointer;
}

#login aside form .form-group i,
#register aside form .form-group i,
#sign-in-dialog .form-group i {
  font-size: 1.3125rem;
  position: absolute;
  left: 12px;
  top: 34px;
  color: #ccc;
  width: 25px;
  height: 25px;
  display: block;
  font-weight: 400 !important;
}

#login aside .copy,
#register aside .copy {
  text-align: center;
  position: absolute;
  height: 30px;
  left: 0;
  bottom: 30px;
  width: 100%;
  color: #999;
  font-weight: 500;
}

.access_social {
  margin-top: 45px;
}

a.social_bt.linkedin {
  background-color: #0077b5;
}

a.social_bt.linkedin:before {
  content: "\e09d";
}

#pass-info {
  width: 100%;
  margin-bottom: 15px;
  color: #555;
  text-align: center;
  font-size: 0.75rem;
  padding: 5px 3px 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

#pass-info.weakpass {
  border: 1px solid #ff9191;
  background: #ffc7c7;
  color: #94546e;
}

#pass-info.stillweakpass {
  border: 1px solid #fbb;
  background: #fdd;
  color: #945870;
}

#pass-info.goodpass {
  border: 1px solid #c4eec8;
  background: #e4ffe4;
  color: #51926e;
}

#pass-info.strongpass {
  border: 1px solid #6ed66e;
  background: #79f079;
  color: #348f34;
}

#pass-info.vrystrongpass {
  border: 1px solid #379137;
  background: #48b448;
  color: #cdffcd;
}

.container_check {
  display: block;
  font-size: 0.875rem;
  line-height: 1.4;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container_radio,
.step .nav-link {
  font-size: 0.9375rem;
  display: block;
}

.container_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.container_check input:checked ~ .checkmark {
  background-color: #004dda;
  border: 1px solid transparent;
}

.container_check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #d2d8dd;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  transition: 0.3s ease-in-out;
}

.container_radio .checkmark:after,
.search_bar_error input[type="submit"] {
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
}

.container_check .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.container_radio {
  line-height: 1.3;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container_radio input {
  position: absolute;
  opacity: 0;
}

.container_radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.container_radio .checkmark:after {
  display: block;
  content: "";
  position: absolute;
  opacity: 0;
  transition: 0.3s ease-in-out;
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #004dda;
}

.step .nav-link {
  padding: 0 15px;
  height: 30px;
  color: #444;
}

.leaflet-control-attribution a,
.nav-link:focus,
.step .nav-link:hover {
  text-decoration: none;
}

.step .nav-link.disabled {
  color: #6c757d;
}

.step .nav-tabs {
  border: none;
  border-bottom: 2px solid #dee2e6;
}

.step .nav-tabs .nav-item {
  margin-bottom: -2px;
}

.step .nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.box_general.summary ul li,
.payments ul li {
  border-bottom: 1px solid #ededed;
  margin-bottom: 8px;
}

.step .nav-tabs .nav-item.show .nav-link,
.step .nav-tabs .nav-link.active {
  border-bottom: 2px solid #004dda;
  color: #004dda;
  background-color: transparent;
}

.step .tab-content.checkout hr {
  margin: 10px 0;
}

.step h3:before {
  width: 0;
  height: 0;
  border-top: 20px inset transparent;
  border-bottom: 20px inset transparent;
  border-left: 10px solid #f8f8f8;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
}

.step h3:after {
  width: 0;
  height: 0;
  border-top: 20px inset transparent;
  border-bottom: 20px inset transparent;
  border-left: 10px solid #333;
  position: absolute;
  content: "";
  top: 0;
  right: -10px;
  z-index: 2;
}

.step h3 {
  padding: 10px 12px 10px 20px;
  background: #333;
  position: relative;
  margin-bottom: 15px;
  color: #fff;
  font-size: 1rem;
}

.step #forgot_pw {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
}

.payments ul,
ul.list_ok {
  list-style: none;
  margin: 0 0 30px;
  padding: 0;
}

.payments ul li a.info {
  display: inline-block;
  float: right;
  color: #444;
}

.payments ul li a.info:after {
  font-family: themify;
  content: "\e718";
  position: relative;
  right: 0;
  top: 0;
  font-size: 0.9375rem;
}

.payment_info figure img {
  height: 20px;
  width: auto;
}

.payment_info p {
  font-size: 0.8125rem;
}

.box_general.summary {
  background-color: #fff;
  padding-bottom: 20px;
}

.box_general.summary ul {
  list-style: none;
  margin: 0 0 20px;
  padding: 0;
}

.box_general.summary ul li {
  padding-bottom: 6px;
  font-weight: 500;
}

.box_general.summary ul li:last-child {
  font-weight: 700;
  border-bottom: none;
  color: red;
}

.box_general.summary label.container_check {
  font-size: 0.75rem;
  padding-top: 4px;
}

#confirm {
  text-align: center;
  padding: 60px 15px;
}

@-webkit-keyframes checkmark {
  0% {
    stroke-dashoffset: 50px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@-ms-keyframes checkmark {
  0% {
    stroke-dashoffset: 50px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes checkmark {
  0% {
    stroke-dashoffset: 50px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 240px;
  }

  100% {
    stroke-dashoffset: 480px;
  }
}

@-ms-keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 240px;
  }

  100% {
    stroke-dashoffset: 480px;
  }
}

@keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 240px;
  }

  100% {
    stroke-dashoffset: 480px;
  }
}

.inlinesvg .svg svg {
  display: inline;
}

.icon--order-success svg path {
  -webkit-animation: 0.25s ease-in-out 0.7s backwards checkmark;
  animation: 0.25s ease-in-out 0.7s backwards checkmark;
}

.icon--order-success svg circle {
  -webkit-animation: 0.6s ease-in-out backwards checkmark-circle;
  animation: 0.6s ease-in-out backwards checkmark-circle;
}

.box_account h3 {
  font-size: 1.3125rem;
  /* padding-left: 30px; */
  padding-left: 0;
  height: 30px;
  padding-top: 5px;
  display: inline-block;
  margin-bottom: 15px;
}

.box_account h3.client {
  background: url(../fonts/user.svg) center left no-repeat;
}

.box_account h3.new_client {
  /* background: url(../fonts/new_user.svg) center left no-repeat */
  background: none;
}

.box_account .form_container {
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 25px;
  position: relative;
}

.box_account .form_container a.social_bt {
  margin-bottom: 0;
  min-width: inherit;
}

.add_top_20,
.booking_buttons,
.box_account .form_container .divider {
  margin-top: 20px;
}

.bg_color_1,
.box_account .form_container .divider span {
  background-color: #fff;
}

.box_account .form_container #forgot_pw {
  height: 100% !important;
  min-height: inherit;
  background-color: #fff;
  padding: 25px;
}

.cluster img,
.drp-selected {
  display: none !important;
}

.cluster-visible {
  text-align: center;
  font-size: 15px !important;
  color: #fff !important;
  font-weight: 500;
  border-radius: 50%;
  width: 36px !important;
  height: 36px !important;
  line-height: 36px !important;
  background-color: #004dda !important;
  background-image: none !important;
}

.cluster-visible:before {
  border: 7px solid #004dda;
  opacity: 0.2;
  box-shadow: inset 0 0 0 4px #004dda;
  content: "";
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: 2.5s infinite cluster-animation;
}

@keyframes cluster-animation {
  0%,
  100% {
    transform: scale(1.3) rotate(0);
  }

  50% {
    transform: scale(1.5) rotate(90deg);
  }
}

.map {
  width: 100%;
  height: 500px;
}

.map.map_single {
  height: 400px;
}

.infoBox {
  -webkit-animation: 0.9s fadeIn;
  animation: 0.9s fadeIn;
  padding-right: 50px;
}

.infoBox > img {
  position: absolute !important;
  right: 60px !important;
  top: 10px !important;
  z-index: 9999;
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.marker_info {
  width: 240px;
  height: 270px;
  border-radius: 5px;
  text-align: left;
  background: #fff;
  position: relative;
  z-index: 999;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
}

.leaflet-popup-content img,
.marker_info img {
  display: block;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -ms-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
}

.marker_info h3 {
  font-size: 1rem;
  line-height: 1.1;
  font-weight: 500;
  margin-top: 3px;
  color: #444;
}

.leaflet-popup-content em,
.marker_info em {
  display: inline-block;
  font-size: 0.75rem;
  color: #999;
  font-style: normal;
}

.leaflet-popup-content span,
.marker_info span {
  display: block;
  padding: 15px 20px 0;
  font-size: 0.8125rem;
  line-height: 1.2;
  color: #fff;
  position: relative;
}

.leaflet-popup-content span strong,
.marker_info span strong {
  display: block;
  font-weight: 500;
}

.marker_info:after {
  right: 100%;
  top: 56%;
  border: 12px solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent #fff transparent transparent;
  margin-top: -12px;
}

a.btn_infobox_detail {
  position: absolute;
  top: 20px;
  right: 15px;
  width: 20px;
  height: 20px;
}

.btn_infobox_get_directions:before,
a.btn_infobox_detail:before,
a.btn_infobox_phone:before {
  font-style: normal;
  font-weight: 400;
  font-family: ElegantIcons;
  font-size: 1.25rem;
  cursor: pointer;
}

a.btn_infobox_detail:before {
  content: "\70";
  color: #ccc;
}

.btn_infobox_get_directions,
a.btn_infobox_phone {
  border: none;
  display: inline-block;
  font-weight: 500;
  color: #004dda;
  background: 0 0;
  cursor: pointer;
  font-size: 0.8125rem;
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
  outline: 0;
}

.list_articles ul li,
.strip_booking,
ul#cat_nav li {
  border-bottom: 1px solid #ededed;
}

.btn_infobox_get_directions:before {
  font-size: 0.875rem;
  content: "\e080";
  position: absolute;
  left: 0;
  top: 0;
}

a.btn_infobox_phone:before {
  font-size: 14px;
  content: "\e090";
  position: absolute;
  left: 0;
  top: -2px;
}

span.infobox_rate {
  display: inline-block;
  margin: -44px 0 0 -20px;
  float: left;
  background-color: #0054a6;
  padding: 5px 8px;
  font-weight: 500;
  text-align: left;
  font-size: 0.75rem;
}

#map_contact {
  width: 100%;
  height: 450px;
}

iframe#map_iframe {
  width: 100%;
  height: 450px;
  border: 0;
}

.box_contacts {
  background-color: #fff;
  padding: 25px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  margin-bottom: 15px;
  text-align: center;
  font-size: 0.9375rem;
}

.box_contacts h2 {
  font-weight: 500;
  margin: 10px 0;
  font-size: 1.3125rem;
}

.box_contacts i {
  font-size: 2.875rem;
  color: #004dda;
}

.box_contacts a:hover {
  color: #004dda;
  text-decoration: underline;
}

.error_message {
  font-weight: 500;
  color: #e3464c;
}

#error_page {
  background: url(../fonts/pattern_1.svg) center bottom repeat-x #0054a6;
  color: #fff;
}

#error_page .wrapper {
  background: rgba(0, 0, 0, 0.6);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.6), transparent);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), transparent);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#error_page h2 {
  margin-top: 30px;
  font-size: 8.75rem;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1;
}

.search_bar_error {
  position: relative;
  margin-bottom: 60px;
}

.search_bar_error input[type="text"] {
  border: 0;
  height: 50px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding-left: 15px;
  -webkit-box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
}

.box_style_cat,
a.box_topic {
  background: #fff;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
}

.search_bar_error input[type="submit"] {
  position: absolute;
  right: -1px;
  color: #222;
  font-weight: 600;
  top: 0;
  border: 0;
  padding: 0 25px;
  height: 50px;
  cursor: pointer;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -ms-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  background-color: #ffc107;
  transition: 0.3s ease-in-out;
}

.grid-gallery ul li figcaption,
.grid-gallery ul li figure img {
  width: 100%;
  height: 100%;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
}

.grid-gallery ul {
  margin: 0 0 25px;
  padding: 0;
  width: 100%;
  text-align: center;
}

.grid-gallery ul li {
  display: inline-block;
  margin: -3px;
  padding: 0;
  min-height: 100%;
  width: 25%;
  background-color: #000;
  list-style: none;
}

.grid-gallery ul li figure {
  position: relative;
  overflow: hidden;
  margin: -3px;
}

.grid-gallery ul li figure img {
  transition: 0.3s ease-in-out;
}

.grid-gallery ul li figure:focus img,
.grid-gallery ul li figure:hover img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.grid-gallery ul li figure:focus figcaption .caption-content,
.grid-gallery ul li figure:hover figcaption .caption-content {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.grid-gallery ul li figcaption {
  position: absolute;
  top: 0;
  left: 0;
  padding: 15% 0;
  background-color: rgba(0, 0, 0, 0.6);
  text-align: center;
  font-size: 0.875rem;
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.grid-gallery ul li figcaption .caption-content {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -100px;
  width: 200px;
  -webkit-transform: translate(0, 15px);
  -moz-transform: translate(0, 15px);
  -ms-transform: translate(0, 15px);
  -o-transform: translate(0, 15px);
  transform: translate(0, 15px);
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.grid-gallery ul li figcaption i {
  font-size: 1.875rem;
}

.grid-gallery ul li figcaption p {
  margin-bottom: 0;
  margin-top: 10px;
  text-transform: uppercase;
  font-weight: 400;
}

.mfp-zoom-in .mfp-with-anim {
  opacity: 0;
  transition: 0.2s ease-in-out;
  transform: scale(0.8);
}

.mfp-zoom-in.mfp-bg {
  opacity: 0;
  transition: 0.3s ease-out;
}

.block-reveal img,
a.box_topic {
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
}

.mfp-zoom-in.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: scale(1);
}

.mfp-zoom-in.mfp-removing .mfp-with-anim {
  transform: scale(0.8);
  opacity: 0;
}

.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

.box_style_cat {
  border: 1px solid #ededed;
  margin-bottom: 25px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
}

.list_articles ul,
ul#cat_nav,
ul.list_3 {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul#cat_nav li i {
  font-size: 1rem;
  margin: 2px 10px 0 0;
  float: left;
}

ul#cat_nav li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

ul#cat_nav li a {
  position: relative;
  color: #555;
  display: block;
  padding: 15px 10px;
}

ul#cat_nav li a:after {
  font-family: fontello;
  content: "\e89b";
  position: absolute;
  right: 15px;
  top: 15px;
}

ul#cat_nav li a span {
  font-size: 0.6875rem;
  color: #999;
}

.hero_in form {
  animation-delay: 1.3s;
  -webkit-animation-delay: 1.3s;
  -moz-animation-delay: 1.3s;
}

a.box_topic {
  text-align: center;
  padding: 40px 30px 15px;
  display: block;
  transition: 0.3s ease-in-out;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  color: #555;
  margin-bottom: 30px;
  overflow: hidden;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

a.box_topic:hover {
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  transform: translateY(-10px);
}

a.box_topic i {
  font-size: 3.75rem;
  /* color: #32a067; */
  color: #f0151f;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px;
  background-color: #f0151f0a;
  width: 100px;
  height: 100px;
  line-height: 100px;
  margin-bottom: 15px;
}

.list_articles ul li {
  float: left;
  width: 45%;
  margin-right: 45px;
  padding: 15px 0;
}

.list_articles ul li a {
  color: #555;
  display: block;
}

.list_articles ul li a:hover {
  color: #004dda;
  -webkit-transform: translateX(5px);
  -moz-transform: translateX(5px);
  -ms-transform: translateX(5px);
  -o-transform: translateX(5px);
  transform: translateX(5px);
}

.list_articles ul li i {
  margin-right: 10px;
  font-size: 1rem;
  position: relative;
  top: 2px;
}

ul.list_3 li {
  margin-bottom: 0;
  position: relative;
  padding-left: 20px;
}

#forgot_pw label,
.nice-select .option.selected,
ul.list_3 li strong {
  font-weight: 500;
}

ul.list_3 li:before {
  font-family: fontello;
  content: "\ea3e";
  position: absolute;
  left: 0;
  top: 0;
}

.box_general {
  border: 1px solid #ededed;
  padding: 25px 25px 5px;
  margin-bottom: 25px;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
}

.box_booking {
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 25px;
  margin-bottom: 25px;
}

.strip_booking {
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.strip_booking h3 {
  font-size: 1rem;
  text-transform: uppercase;
  position: relative;
  top: 10px;
  padding-left: 65px;
}

.strip_booking h3 span {
  color: #999;
  display: block;
  font-size: 11px !important;
}

.strip_booking .date {
  background-color: #f8f8f8;
  text-align: center;
  line-height: 1;
}

.strip_booking .date .month {
  background-color: #32a067;
  display: block;
  padding: 3px 0;
  color: #fff;
  text-transform: uppercase;
}

.strip_booking .date .day {
  padding: 15px;
  margin-top: 1px;
  color: #999;
  text-transform: uppercase;
  display: block;
  font-size: 0.75rem;
}

.strip_booking .date .day strong {
  font-size: 2rem;
  font-weight: 400;
  display: block;
  color: #32a067;
  margin-bottom: 3px;
}

.strip_booking h3.bars_booking:before,
.strip_booking h3.hotel_booking:before,
.strip_booking h3.restaurant_booking:before {
  font-style: normal;
  font-weight: 400;
  font-family: fontello;
  font-size: 1.5rem;
  color: #999;
  position: absolute;
  border: 1px solid #ededed;
  width: 50px;
  height: 50px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  left: 0;
  top: -10px;
  text-align: center;
  line-height: 50px;
}

.strip_booking h3.hotel_booking:before {
  content: "\ed72";
}

.strip_booking h3.restaurant_booking:before {
  content: "\ed80";
}

.strip_booking h3.bars_booking:before {
  content: "\ed54";
}

ul.info_booking {
  list-style: none;
  margin: 0;
  padding: 15px 25px 15px 0;
  font-size: 0.6875rem;
  color: #999;
  line-height: 12px;
  border-right: 1px solid #ededed;
  text-align: right;
}

ul.info_booking li strong {
  text-transform: uppercase;
  display: block;
  color: #555;
}

.btn_2,
.btn_3,
a.btn_2,
a.btn_3 {
  border: none;
  font-family: inherit;
  color: #fff;
  background: #004dda;
  cursor: pointer;
  padding: 7px 8px;
  font-size: 0.6875rem;
  line-height: 9px;
  font-weight: 500;
  display: block;
  outline: 0;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  text-align: center;
}

.btn_2:hover,
.btn_3:hover,
a.btn_2:hover,
a.btn_3:hover {
  background: #ffc107;
  color: #444;
}

.btn_3,
a.btn_3 {
  color: #999;
  background: #eee;
  min-width: 60px;
  text-align: center;
  margin-top: 3px;
}

.pac-container {
  margin-top: 5px;
  border-top: none !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  -ms-border-radius: 4px !important;
  border-radius: 4px !important;
  font-family: "Bell MT";
  color: #555;
  -webkit-box-shadow: 0 12px 35px 2px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 12px 35px 2px rgba(0, 0, 0, 0.12);
  box-shadow: 0 12px 35px 2px rgba(0, 0, 0, 0.12);
}

.leaflet-image-layer,
.leaflet-layer,
.leaflet-map-pane,
.leaflet-marker-icon,
.leaflet-marker-pane,
.leaflet-marker-shadow,
.leaflet-overlay-pane,
.leaflet-overlay-pane svg,
.leaflet-popup-pane,
.leaflet-shadow-pane,
.leaflet-tile,
.leaflet-tile-container,
.leaflet-tile-pane,
.leaflet-zoom-box {
  position: absolute;
  left: 0;
  top: 0;
}

.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.leaflet-container img {
  max-width: none !important;
}

.leaflet-container img.leaflet-image-layer {
  max-width: 15000px !important;
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  width: 0;
  height: 0;
}

.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-tile-pane {
  z-index: 2;
}

.leaflet-objects-pane {
  z-index: 3;
}

.leaflet-overlay-pane {
  z-index: 4;
}

.leaflet-shadow-pane {
  z-index: 5;
}

.leaflet-marker-pane {
  z-index: 6;
}

.leaflet-popup-pane {
  z-index: 7;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  // behavior: url(style.scss#default#VML);
  display: inline-block;
  position: absolute;
}

.leaflet-control {
  position: relative;
  z-index: 7;
  pointer-events: auto;
  float: left;
  clear: both;
}

.leaflet-bottom,
.leaflet-top {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-fade-anim .leaflet-popup,
.leaflet-fade-anim .leaflet-tile {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

#toTop,
.closed .horizontal {
  opacity: 1;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
}

.leaflet-pan-anim .leaflet-tile,
.leaflet-touching .leaflet-zoom-animated,
.leaflet-zoom-anim .leaflet-tile {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}

.leaflet-clickable {
  cursor: pointer;
}

.leaflet-container {
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  background: #ddd;
  outline: 0;
  font:
    12px/1.5 "Helvetica Neue",
    Arial,
    Helvetica,
    sans-serif;
}

.leaflet-control,
.leaflet-popup-pane {
  cursor: auto;
}

.leaflet-dragging .leaflet-clickable,
.leaflet-dragging .leaflet-container {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}

.leaflet-container a {
  color: #0078a8;
}

.leaflet-container a.leaflet-active {
  outline: orange solid 2px;
}

.leaflet-zoom-box {
  border: 2px dotted #38f;
  background: rgba(255, 255, 255, 0.5);
}

.leaflet-bar {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
}

.leaflet-bar a,
.leaflet-bar a:hover {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: #000;
}

.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font:
    bold 18px "Lucida Console",
    Monaco,
    monospace;
  text-indent: 1px;
}

.leaflet-control-zoom-out {
  font-size: 20px;
}

.leaflet-touch .leaflet-control-zoom-in {
  font-size: 22px;
}

.leaflet-touch .leaflet-control-zoom-out {
  font-size: 24px;
}

.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 5px;
}

.leaflet-control-layers-toggle {
  background-image: url(../images/layers.png);
  width: 36px;
  height: 36px;
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(../images/layers-2x.png);
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px;
}

.leaflet-container .leaflet-control-attribution {
  background: rgba(255, 255, 255, 0.7);
  margin: 0;
}

.circle-plus .horizontal,
.circle-plus .vertical {
  background-color: #999;
  top: 50%;
  position: absolute;
}

.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333;
}

.leaflet-control-attribution a:hover {
  text-decoration: underline;
}

.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
  font-size: 11px;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  background: rgba(255, 255, 255, 0.5);
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-bar,
.leaflet-touch .leaflet-control-layers {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}

.leaflet-popup {
  position: absolute;
  text-align: center;
}

.leaflet-popup-content {
  line-height: 1.4;
}

.leaflet-popup-content p {
  margin: 18px 0;
}

.leaflet-popup-tip-container {
  margin: 0 auto;
  width: 40px;
  height: 20px;
  position: relative;
  overflow: hidden;
}

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: #fff;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}

.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  font:
    700 16px/14px Tahoma,
    Verdana,
    sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  background: 0 0;
}

.leaflet-container a.leaflet-popup-close-button:hover {
  color: #999;
}

.leaflet-popup-scrolled {
  overflow: auto;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;
}

.leaflet-oldie .leaflet-popup-tip-container {
  margin-top: -1px;
}

.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow {
  -webkit-transition:
    -webkit-transform 0.3s ease-out,
    opacity 0.3s ease-in;
  -moz-transition:
    -moz-transform 0.3s ease-out,
    opacity 0.3s ease-in;
  -o-transition:
    -o-transform 0.3s ease-out,
    opacity 0.3s ease-in;
  transition:
    transform 0.3s ease-out,
    opacity 0.3s ease-in;
}

.closed .horizontal,
.closed .vertical {
  transition: 0.5s ease-in-out;
  transform: rotate(-90deg);
}

.marker-cluster-large,
.marker-cluster-medium,
.marker-cluster-small {
  background-color: transparent;
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}

.marker-cluster div {
  text-align: center;
  font-size: 15px;
  color: #fff;
  font-weight: 700;
  margin-left: 5px;
  margin-top: 5px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  background-color: #004dda;
  position: relative;
  border-radius: 50%;
}

.marker-cluster div:before {
  content: "";
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  border: 7px solid #004dda;
  opacity: 0.2;
  box-shadow: inset 0 0 0 4px #004dda;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: 2.5s infinite cluster-animation;
}

.marker-cluster span {
  font-family: Arial, sans-serif;
  line-height: 36px;
}

.leaflet-popup-content-wrapper {
  text-align: left;
  width: 240px;
  height: 270px;
  padding: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  border-radius: 5px !important;
}

.leaflet-popup-content {
  margin: 0;
  font-family: "Bell MT";
  position: relative;
}

.leaflet-popup-content h3 {
  font-size: 1rem;
  margin-bottom: 15px;
  line-height: 1.1;
  font-weight: 500;
  margin-top: 3px;
  color: #444;
}

.leaflet-popup-content span.infobox_rate {
  display: inline-block;
  margin: -39px 0 0 -20px;
  float: left;
  background-color: #0054a6;
  padding: 5px 8px;
  font-weight: 500;
  text-align: left;
  font-size: 0.75rem;
}

.leaflet-container a.leaflet-popup-close-button {
  z-index: 9999;
  background-color: #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  top: 5px;
  right: 5px;
  padding: 0;
  line-height: 18px;
  text-align: center;
}

.opened .horizontal,
.opened .vertical {
  transition: 0.5s ease-in-out;
  transform: rotate(90deg);
}

.opened .horizontal {
  opacity: 0;
}

.circle-plus {
  height: 14px;
  width: 14px;
  display: block;
  position: relative;
  float: right;
  margin-top: 5px;
}

.circle-plus .horizontal {
  width: 14px;
  height: 2px;
  left: 50%;
  margin-left: -8px;
  margin-top: 0;
}

.circle-plus .vertical {
  width: 2px;
  height: 14px;
  left: 50%;
  margin-left: -2px;
  margin-top: -6px;
}
.form-select {
  border-radius: 3px;
  color: #495057;
  border: 1px solid #d2d8dd;
}
.input-group-text{
  border-color: #d2d8dd;
}

.form-control {
  // font-size: 0.875rem;
  border-radius: 3px;
  color: #495057;
  border: 1px solid #d2d8dd;
}

.nice-select,
.pagination .next,
.pagination .prev {
  font-size: 14px;
}

.form-group {
  position: relative;
  margin-bottom: 1rem;
}

.box_1 {
  background-color: #121921;
  color: #fff;
  padding: 45px;
  opacity: 0;
}

.animated .box_1 {
  animation: 0.3s ease-in-out 0.3s forwards color;
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  opacity: 0;
  backface-visibility: hidden;
}

.call_section {
  background-color: #004dda;
  position: relative;
  color: #fff;
}

.call_section .wrapper {
  background-color: rgba(0, 0, 0, 0.7);
  position: relative;
  z-index: 22;
}

.call_section .box_how {
  text-align: center;
  position: relative;
}

.call_section .box_how h3 {
  color: #fff;
  font-size: 1.125rem;
}

.call_section .box_how i {
  font-size: 3.75rem;
  margin-bottom: 10px;
  color: #ffc107;
}

.call_section.pattern {
  background: url(../fonts/pattern.svg) center center fixed #004dda;
}

.call_section.image_bg {
  /* background: url(../images/bg_call_section.jpg) center center/cover no-repeat
    fixed #004dda; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.nice-select,
.nice-select .list {
  background-color: #fff;
  box-sizing: border-box;
}

.call_section.image_bg .wrapper {
  background-color: rgba(0, 0, 0, 0.8);
}

#hero-canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.clear:after,
.clear:before,
.pagination:after,
.pagination:before {
  content: "";
  display: table;
}

.clear:after,
.pagination:after {
  clear: both;
}

.pagination__wrapper {
  height: 50px;
  padding-top: 10px;
  margin-bottom: 0;
  position: relative;
  text-align: center;
  width: 100%;
}

.pagination__wrapper:before {
  top: -1px;
}

.pagination__wrapper:after {
  bottom: -1px;
}

@keyframes hoverAnimation {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.pagination {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
}

.pagination li {
  display: block;
  float: left;
  padding: 5px;
}

.pagination a,
.pagination span {
  background: 0 0;
  border: none;
  border-radius: 50%;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.6);
  display: block;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  min-width: 40px;
  padding: 0;
}

.pagination a {
  outline: 0;
  position: relative;
  transition: 170ms linear;
}

.pagination a:before {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  content: "";
  cursor: pointer;
  height: 0;
  left: 50%;
  opacity: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  transition: 170ms linear;
  top: 50%;
  width: 0;
}

.pagination a:hover:not(.active) {
  color: #000;
}

.pagination a:hover:not(.active):before {
  animation: 510ms linear forwards hoverAnimation;
  height: 40px;
  width: 40px;
}

.pagination a.active {
  background: #004dda;
  color: #fff;
  cursor: default;
}

.box_faq {
  position: relative;
  padding-left: 50px;
}

.box_faq i {
  font-size: 2.25rem;
  position: absolute;
  left: 0;
  top: 0;
  color: #999;
}

.form-select-form .nice-select {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #d2d8dd;
  height: 45px;
  line-height: 42px;
}

.form-select-form .nice-select.open,
.form-select-form .nice-select:active,
.form-select-form .nice-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

ul.share-buttons {
  padding: 0;
  list-style: none;
  text-align: center;
  margin: 0 0 25px;
}

ul.share-buttons li {
  display: inline-block;
  margin: 0 5px 5px;
}

ul.share-buttons li a {
  background: #fff;
  border: 1px solid #ededed;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  font-size: 0.8125rem;
  padding: 7px 20px;
  transition: 0.3s;
  display: inline-block;
  line-height: 17px;
  font-weight: 500;
}

ul.share-buttons li a.fb-share {
  color: #3b5998;
}

ul.share-buttons li a.fb-share:hover {
  background: #3b5998;
  color: #fff;
  border-color: #3b5998;
}

ul.share-buttons li a.gplus-share {
  color: #dd4b39;
}

ul.share-buttons li a.gplus-share:hover {
  background: #dd4b39;
  color: #fff;
  border-color: #dd4b39;
}

ul.share-buttons li a.twitter-share {
  color: #1da1f2;
}

ul.share-buttons li a.twitter-share:hover {
  background: #1da1f2;
  color: #fff;
  border-color: #1da1f2;
}

ul.share-buttons li i {
  font-size: 1rem;
  position: relative;
  right: 3px;
  top: 2px;
}

.nice-select {
  -webkit-tap-highlight-color: transparent;
  border-radius: 3px;
  border: none;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-weight: 400;
  height: 50px;
  line-height: 48px;
  outline: 0;
  padding-left: 15px;
  padding-right: 27px;
  position: relative;
  text-align: left !important;
  transition: 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: auto;
  color: #555;
  padding-top: 2px;
}

#cookie-bar,
#toTop,
.divider,
a.social_bt {
  text-align: center;
}

.nice-select:hover {
  border-color: #8c8c8c;
}

.nice-select.open,
.nice-select:active,
.nice-select:focus {
  border-color: #999;
}

.nice-select:after {
  border-bottom: 2px solid #999;
  border-right: 2px solid #999;
  content: "";
  display: block;
  height: 8px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  right: 20px;
  top: 50%;
  transform-origin: 66% 66%;
  transform: rotate(45deg);
  transition: 0.15s ease-in-out;
  width: 8px;
}

.nice-select.open:after {
  transform: rotate(-135deg);
}

.nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1) translateY(0);
}

.nice-select.disabled {
  border-color: #9e9e9e;
  color: #999;
  pointer-events: none;
}

.nice-select.disabled:after {
  border-color: #ccc;
}

.nice-select.wide {
  width: 100%;
}

.nice-select.wide .list {
  left: -1px !important;
  right: -1px !important;
}

.nice-select.right .list {
  left: auto;
  right: 0;
}

.nice-select.small {
  font-size: 12px;
  height: 36px;
  line-height: 34px;
}

.nice-select.small:after {
  height: 4px;
  width: 4px;
}

.nice-select.small .option {
  line-height: 34px;
  min-height: 34px;
}

.nice-select .list {
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  margin-top: 4px;
  opacity: 0;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  transform-origin: 50% 0;
  transform: scale(0.75) translateY(-25px);
  transition:
    0.2s cubic-bezier(0.5, 0, 0, 1.25),
    opacity 0.15s ease-out;
  z-index: 9999;
  height: 23vh;
  overflow: auto;
}

.nice-select .list:hover .option:not(:hover) {
  background-color: transparent !important;
}

.nice-select .list::-webkit-scrollbar {
  width: 14px;
  height: 18px;
}

.nice-select .list::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid transparent;
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow:
    inset -1px -1px 0 rgba(0, 0, 0, 0.05),
    inset 1px 1px 0 rgba(0, 0, 0, 0.05);
}

.nice-select .list::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

.nice-select .list::-webkit-scrollbar-corner {
  background-color: transparent;
}

.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 38px;
  list-style: none;
  min-height: 38px;
  outline: 0;
  padding-left: 15px;
  padding-right: 26px;
  text-align: left;
  transition: 0.2s;
}

.nice-select .option.focus,
.nice-select .option.selected.focus,
.nice-select .option:hover {
  background-color: #f6f6f6;
}

.nice-select .option.disabled {
  background-color: transparent;
  color: #999;
  cursor: default;
}

#toTop {
  position: fixed;
  right: 25px;
  bottom: 25px;
  z-index: 9999;
  width: 46px;
  height: 46px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  font-size: 21px;
  color: #fff;
  cursor: pointer;
}

#cookie-bar,
a#forgot {
  font-size: 0.8125rem;
  font-weight: 500;
}

#toTop:after {
  content: "\e899";
  font-family: fontello;
  position: relative;
  display: block;
  top: 50%;
  -webkit-transform: translateY(-55%);
  transform: translateY(-55%);
}

.accordion_2 .card {
  border: 0;
  margin-bottom: 5px;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
}

.accordion_2 .card .card-header {
  background-color: #fff;
  border-bottom: 1px solid #ededed;
  padding: 20px;
}

.accordion_2 .card-header h5 a {
  border: 0;
  display: block;
  color: #999;
}

ul.bullets li:before,
ul.list_ok li:before {
  color: #32a067;
  left: 0;
  font-family: ElegantIcons;
  position: absolute;
}

.accordion_2 .card-header h5 a i.indicator {
  font-size: 1.125rem;
  font-weight: 400;
  float: right;
  color: #004dda;
}

.card {
  border-radius: 0;
  border: 0;
  margin-bottom: 5px;
}

ul.list_ok li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
}

ul.list_ok li:before {
  content: "\52";
  top: -5px;
  font-size: 1.125rem;
}

ul.bullets {
  line-height: 1.8;
  margin: 0;
  padding: 0;
}

ul.bullets li {
  position: relative;
  padding-left: 23px;
}

ul.bullets li:before {
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  content: "\e052";
  top: 0;
}

.add_bottom_45 {
  margin-bottom: 45px;
}

.add_bottom_60 {
  margin-bottom: 60px;
}

.add_bottom_75 {
  margin-bottom: 75px;
}

.add_top_15 {
  margin-top: 15px;
}

.add_top_30 {
  margin-top: 30px;
}

.add_top_60 {
  margin-top: 60px;
}

.nomargin_top {
  margin-top: 0;
}

.nomargin,
.nopadding {
  margin: 0 !important;
}

.nopadding {
  padding: 0 !important;
}

.margin_30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.margin_30_5 {
  padding-top: 30px;
  padding-bottom: 5px;
}

.margin_60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.margin_60_35 {
  padding-top: 60px;
  padding-bottom: 35px;
}

.margin_80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

@media (max-width: 991px) {
  #results h4,
  #results_map_view h4 {
    margin: 5px 0 0;
  }

  .strip.list_view figure {
    min-height: 220px;
    max-height: 220px;
    height: 220px;
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    -ms-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
  }

  .strip.list_view figure a img {
    max-width: inherit;
    height: 460px;
    width: auto;
  }

  .strip.list_view .wrapper {
    min-height: inherit;
    position: relative;
  }

  .box_style_cat,
  .strip.map_view a.address {
    display: none;
  }

  .strip.map_view figure {
    height: 165px;
  }

  .strip.map_view figure a img {
    max-width: inherit;
    height: 210px;
    width: auto;
  }

  .strip.map_view .wrapper {
    min-height: 90px;
    padding-right: 45px;
  }

  .box_list figure small {
    right: 15px;
    top: 15px;
  }

  .box_list figure {
    min-height: 220px;
    max-height: 220px;
    height: 220px;
  }

  .box_list figure a img {
    max-width: inherit;
    height: 450px;
    width: auto;
  }

  #list_sidebar .box_list .wrapper,
  .box_list .wrapper {
    min-height: inherit;
  }

  #list_sidebar .box_list figure {
    min-height: 200px;
    max-height: 200px;
    height: 200px;
  }

  #list_sidebar .box_list figure a img {
    max-width: 100%;
    height: auto;
    width: inherit;
  }

  .search_mob a,
  a.btn_search_mobile {
    display: block;
  }

  #review_summary {
    margin-bottom: 15px;
  }

  .booking .dropdown {
    height: inherit;
  }

  .dropdown {
    /* background-color: #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
   
    height: auto; */
  }

  .full-height {
    height: auto;
  }

  .row-height {
    height: auto;
    padding-top: 48px;
  }

  .map-right {
    height: 400px;
    position: static;
    min-height: inherit;
  }

  .content-left {
    padding-top: 0;
  }

  .step {
    margin-bottom: 35px;
  }

  .box_account .form_container a.social_bt {
    margin-bottom: 5px;
  }

  #map_contact {
    height: 350px;
  }

  iframe#map_iframe {
    height: 400px;
  }

  .grid-gallery ul li {
    width: 50%;
  }

  .list_articles ul li {
    float: none;
    width: 100%;
    margin-right: 0;
  }

  .margin_80 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
}

.margin_80_55 {
  padding-top: 80px;
  padding-bottom: 55px;
}

@media (max-width: 991px) {
  .margin_80_55 {
    padding-top: 60px;
    padding-bottom: 35px;
  }
}

.margin_80_0 {
  padding-top: 80px;
}

@media (max-width: 991px) {
  .margin_80_0 {
    padding-top: 60px;
  }
}

.margin_30_95 {
  padding-top: 30px;
  padding-bottom: 95px;
}

@media (max-width: 991px) {
  .margin_30_95 {
    padding-top: 15px;
    padding-bottom: 35px;
  }

  .hidden_tablet {
    display: none !important;
  }
}

@media (max-width: 575px) {
  h1.main_title_in {
    font-size: 1.5rem;
  }

  .sub_header_in h1 {
    font-size: 1.125rem;
  }

  footer h3 {
    cursor: pointer;
    border-bottom: 1px solid #ededed;
    padding: 12px 0;
    display: block;
    font-size: 1rem;
    margin: 0;
  }

  footer h3:after {
    font-family: themify;
    content: "\e61a";
    width: 20px;
    display: block;
    float: right;
  }

  footer h3.opened:after {
    content: "\e622";
  }

  footer hr {
    display: none;
  }

  footer #newsletter,
  footer ul {
    padding-top: 15px;
  }

  ul#footer-selector {
    /* margin-top: 30px */
    margin-top: 0;
  }

  ul#footer-selector li:last-child {
    margin-top: 5px;
  }

  .hero_single .wrapper h3 {
    font-size: 1.4375rem;
  }

  #hero_video .wrapper h3,
  .hero_single.version_2 .wrapper h3,
  .hero_single.version_4 .wrapper h3,
  .hero_single.version_5 .wrapper h3 {
    font-size: 1.375rem;
  }

  #custom-search-input .search-query {
    padding-left: 15px;
  }

  #custom-search-input input[type="submit"],
  .custom-search-input-2.inner input[type="submit"] {
    text-indent: -999px;
    background: url(../fonts/search.svg) center center no-repeat #004dda;
  }

  a.grid_item,
  a.grid_item figure {
    height: 180px;
  }

  a.grid_item.small {
    height: 170px;
  }

  a.grid_item.small figure {
    height: 170px !important;
  }

  .switch-field label {
    min-width: inherit;
  }

  .secondary_nav ul li:last-child {
    display: inline-block;
  }

  .margin_60_35 {
    padding-top: 30px;
    padding-bottom: 5px;
  }

  .margin_80 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .margin_80_55 {
    padding-top: 45px;
    padding-bottom: 15px;
  }

  .margin_80_0 {
    padding-top: 30px;
  }

  .margin_30_95 {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.animated .block-reveal {
  height: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.block-horizzontal,
.block-vertical {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 10;
}

.animated .block-horizzontal {
  animation: 1s ease-in-out forwards reveal_left_to_right;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  background: #0054a6;
}

.animated .block-vertical {
  animation: 1s ease-in-out forwards reveal_top_to_bottom;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  background: #0054a6;
}

.block-reveal img {
  transition: 0.3s ease-in-out;
  opacity: 0;
}

.animated .block-reveal img {
  animation: 0.5s ease-in-out 0.5s forwards color;
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}

@keyframes reveal_left_to_right {
  0% {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  50% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes reveal_top_to_bottom {
  0% {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  50% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%);
  }
}

@keyframes color {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

#cookie-bar {
  background: rgba(0, 0, 0, 0.8);
  height: auto;
  line-height: 24px;
  color: #eee;
  padding: 15px 0;
}

#cookie-bar a {
  color: #fff;
  display: inline-block;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  text-decoration: none;
  padding: 2px 10px;
  margin-left: 8px;
}

#cookie-bar .cb-enable {
  background: #32a067;
}

#cookie-bar .cb-enable:hover,
#cookie-bar .cb-policy:hover {
  background: #fff;
  color: #0054a6;
}

#cookie-bar .cb-disable {
  background: #900;
}

#cookie-bar .cb-disable:hover {
  background: #b00;
}

#cookie-bar .cb-policy,
.default {
  background: #004dda;
}

#cookie-bar.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999999;
}

#cookie-bar.fixed.bottom {
  bottom: 0;
  top: auto;
}

#sign-in-dialog {
  background: #fff;
  padding: 0 30px 30px;
  text-align: left;
  max-width: 400px;
  margin: 40px auto;
  position: relative;
  box-sizing: border-box;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

#lang-dialog .mfp-close,
#sign-in-dialog .mfp-close {
  color: #666;
  background-color: #e4e4e4;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  top: 12px;
  right: 20px;
  width: 32px;
  height: 32px;
  line-height: 32px;
}

#lang-dialog .mfp-close:hover,
#sign-in-dialog .mfp-close:hover {
  color: #fff;
  background-color: #66676b;
}

#sign-in-dialog .mfp-close:before {
  font-size: 24px;
  font-family: ElegantIcons;
  content: "\4d";
}

.small-dialog-header {
  font-size: 18px;
  width: calc(100% + 60px);
  position: relative;
  left: -30px;
  top: 0;
  -webkit-border-radius: 4px 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px 4px;
  border-radius: 4px;
  display: inline-block;
  background-color: #f6f6f6;
  padding: 18px 20px 15px;
  margin-bottom: 30px;
}

.small-dialog-header h3 {
  padding: 0;
  margin: 0;
  font-size: 1.125rem;
  font-weight: 500;
}

.my-toggle {
  background: #fff;
  border: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  color: #888;
  cursor: pointer;
  font-size: 0.625rem;
  font-weight: 700;
  margin-right: 5px;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  text-transform: uppercase;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.my-toggle:focus,
.my-toggle:hover {
  background-color: #eee;
  color: #555;
  outline: transparent;
}

.hideShowPassword-wrapper {
  width: 100% !important;
}

.checkboxes label {
  color: #999;
  margin-top: 5px;
}

a#forgot {
  color: #999;
}

#forgot_pw {
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  min-height: 430px;
  display: none;
  padding: 25px;
}

@media (max-width: 767px) {
  #reccomended,
  .opening h4 {
    margin-top: 0;
  }

  .owl-theme .owl-dots {
    margin-top: 0 !important;
    margin-bottom: 20px;
  }

  a.box_news {
    min-height: inherit;
    padding-left: 0;
  }

  a.box_news figure {
    position: relative;
    width: auto;
  }

  a.box_news figure img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  a.box_news h4 {
    margin-bottom: 5px;
  }

  #forgot_pw,
  a.box_news ul {
    padding: 0;
  }

  a.box_feat,
  a.box_topic {
    padding: 20px 20px 0;
  }

  #carousel {
    margin-bottom: 30px;
  }

  #results {
    padding: 12px 0;
  }

  #results h4,
  #results_map_view h4 {
    margin: 3px 0 0;
  }

  .filters_listing ul li:nth-child(3),
  .filters_listing.version_2 ul li:nth-child(2),
  .opening i,
  .strip.map_view .score span {
    display: none;
  }

  .filters_listing ul li:nth-child(4) {
    margin-right: 0;
  }

  .filters_listing.version_2 ul li:nth-child(3) {
    display: block;
    margin-right: 0;
  }

  .box_list figure a img,
  .hero_in,
  .hero_in.hotels_detail,
  .hero_in.restaurant_detail,
  .hero_in.shop_detail,
  .strip.list_view figure a img {
    height: 350px;
  }

  .strip.map_view .score {
    position: relative;
    top: 3px;
  }

  .strip.map_view .wrapper h3 {
    font-size: 1rem;
  }

  #search_mobile {
    width: 100%;
    padding: 45px 30px 30px;
  }

  #carousel_in .owl-dots {
    bottom: 0;
  }

  .reviews-container .review-box {
    padding-left: 0;
  }

  .reviews-container .rev-thumb {
    position: static;
    margin-bottom: 10px;
  }

  .room_type h4 {
    margin-top: 25px;
  }

  .opening {
    padding: 15px;
  }

  #login figure,
  #register figure {
    margin: -30px -30px 20px;
    padding: 15px 60px 20px;
  }

  #login aside,
  #register aside {
    width: 100%;
    padding: 30px;
    left: 0;
    margin: 0;
  }

  .access_social {
    margin-top: 30px;
  }

  .step h3:after,
  .step h3:before {
    border: none;
  }

  .step h3 {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
  }

  #error_page {
    background-size: auto 300px;
    padding: 0;
    height: 500px;
  }

  #error_page h2 {
    font-size: 4.5rem;
  }

  #error_page p {
    font-size: 1.125rem;
  }

  .grid-gallery ul li {
    width: 100%;
    min-height: 100%;
    float: none;
  }

  .strip_booking h3 {
    margin: 15px 0;
  }

  #toTop,
  .hidden_mobile {
    display: none !important;
  }

  .margin_60 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  #cookie-bar a {
    display: block;
    max-width: 150px;
    margin: auto auto 5px;
  }

  #cookie-bar {
    padding: 5px 0;
  }
}

.sign-in-wrapper {
  position: relative;
  height: 100%;
}

a.social_bt {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  color: #fff;
  min-width: 200px;
  margin-bottom: 15px;
  display: block;
  padding: 12px;
  line-height: 1;
  position: relative;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

a.social_bt:hover {
  -webkit-filter: brightness(115%);
  filter: brightness(115%);
}

a.social_bt.facebook:before,
a.social_bt.google:before,
a.social_bt.linkedin:before {
  font-family: ElegantIcons;
  position: absolute;
  left: 12px;
  top: 10px;
  font-size: 1rem;
  color: #fff;
}

a.social_bt.facebook {
  background-color: #3b5998;
}

a.social_bt.facebook:before {
  content: "\e093";
}

a.social_bt.google {
  background-color: #dc4e41;
}

a.social_bt.google:before {
  content: "\e096";
  top: 12px;
}

.divider {
  height: 1px;
  margin: 30px 0 20px;
  background-color: #e1e8ed;
}

.divider span {
  position: relative;
  top: -20px;
  background-color: #fff;
  display: inline-block;
  padding: 10px;
  font-style: italic;
}

.mfp-bottom-bar {
  padding: 0 10px !important;
}

.mfp-counter {
  right: 10px !important;
  color: #fff !important;
}

.mfp-bg {
  opacity: 0.8 !important;
}

.applyBtn.btn.btn-sm.btn-primary {
  background-color: #004dda;
  border: 0;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #0054a6 !important;
}

#style-switcher {
  background: #fff;
  width: 205px;
  position: fixed;
  top: 205px;
  z-index: 99;
  left: -205px;
  border-radius: 0 0 4px;
  -webkit-box-shadow: 0 6px 15px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.175);
}

#style-switcher h3 {
  color: #fff;
  font-size: 13px;
  margin: 10px 0;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 0;
}

#style-switcher ul {
  list-style: none;
  padding: 0;
  margin-bottom: 22px;
}

#style-switcher div {
  padding: 6px 18px;
}

#style-switcher h6 {
  background: #f8f8f8;
  color: #333;
  font-size: 14px;
  line-height: 46px;
  padding: 0 0 0 20px;
  margin-top: 0;
  font-weight: 500;
}

#style-switcher h6 a {
  background-color: #f8f8f8;
  display: block;
  height: 46px;
  position: absolute;
  right: -46px;
  top: 0;
  width: 46px;
  border-radius: 0 4px 4px 0;
  font-size: 22px;
  color: #fff;
  text-align: center;
}

#style-switcher h6 a i {
  position: relative;
  top: 4px;
  color: #333;
}

.colors {
  list-style: none;
  margin: 0 0 10px;
  overflow: hidden;
}

.colors li {
  float: left;
  margin: 4px 2px 0;
}

.colors li a {
  display: block;
  width: 17px;
  height: 17px;
  cursor: pointer;
  border-radius: 3px;
}

.aqua {
  background: #1cbbb4;
}

.green_switcher {
  background: #82ca9c;
}

.orange {
  background: #f7941d;
}

.beige {
  background: #bea882;
}

.gray {
  background: #555;
}

.green-2 {
  background: #18a84c;
}

.navy {
  background: #4a5d7d;
}

.peach {
  background: #faaa5d;
}

.purple {
  background: #6449e7;
}

.red {
  background: #f0151f;
}

.violet {
  background: #909;
}

.colors li a.active {
  box-shadow: 0 0 0 2px #fff inset;
}

@media only screen and (max-width: 1024px) {
  #style-switcher {
    display: none;
  }
}

#logo {
  display: inline-block;
  vertical-align: middle;
  width: 120px;
  height: auto;
  cursor: pointer;
  /* background: url(/logo.svg) 0px center / 100% no-repeat; */
}

.main_categories .find-more {
  background-color: #fff;
  border-radius: 3px;
  /* -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1); */
  box-shadow: 0 30px 30px 2px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 13px 0;
  width: 90%;
  margin: auto;
}

.find-more a {
  font-size: 17px;
}

.find-more a i {
  font-size: 23px;
  position: relative;
  top: 4px;
  margin-left: 10px;
}

.quantum-pics .owl-carousel .item figure {
  position: relative;
  margin-bottom: 0;
}

.quantum-pics .owl-carousel .item figcaption {
  position: absolute;
  top: 0;
  left: 0;
  padding: 15% 0;
  background-color: rgba(0, 0, 0, 0.6);
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 0.875rem;
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.quantum-pics .owl-carousel .item figcaption .caption-content {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -100px;
  width: 200px;
  transform: translate(0, 15px);
  transition: 0.3s ease-in-out;
}

.quantum-pics .owl-carousel .item figure:hover figcaption {
  opacity: 1;
}

.quantum-pics .owl-carousel .item figure:hover figcaption .caption-content {
  transform: translate(0, 0);
}

.quantum-pics .owl-carousel .item figcaption a {
  color: #fff;
}

.quantum-pics .owl-carousel .item figcaption i {
  font-size: 1.875rem;
}

.foot-bdr {
  content: " ";
  height: 2px;
  width: 100%;
  background-color: hsl(216.92deg 5.63% 54.71%);
}

.bg-gray1 {
  background-color: #f3f3f3;
}

.bg-gray2 {
  background-color: rgb(237 235 235);
}

.bg-gray3 {
  background-color: #f1f1f1;
}

.sec-img-fixed {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.sec-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.quan-toggle {
  font-size: 33px;
  color: #f0151f;
  border: 0;

  display: inline-flex;
}

.quan-toggle.dropdown-toggle::after {
  content: none;
}

.quan-toggle:active {
  color: #444;
  border: 0;
}

.dropdown .quan-menu {
  /* transform: translate(0px, 53px) !important;
  border-color: #f3f3f3;
  box-shadow: 0 0.25rem 0.625rem rgba(20, 20, 20, 0.1);
  padding: 14px 10px; */
}

.dropdown .quan-menu .dropdown-item:active {
  background-color: #f0151f;
}

.quan-menu a {
  font-size: 15px;
}

.quan-menu a::after {
  content: none;
}

.mobile-user {
  font-size: 21px;
  padding: 6px;
  border-radius: 50%;
  color: red;
  border: 1px solid red;
}
.banner-text {
  display: block;
}
.banner-text p {
  font-size: 17px;
  color: #111;
  margin-bottom: 15px;
}
.dash-nav.navbar-dark .navbar-collapse .navbar-sidenav > .nav-item > .nav-link:hover {
  color: #f8f9fa;
  background-color: #e9ecef;
}
/* dashboard css */
/* .navbar-expand-lg .navbar-toggler {
    display: none;
}
.dash-nav.navbar-expand-lg .navbar-toggler {
    display: block !important;
}

@media (min-width: 2000px){

    .dash-nav.navbar-expand-lg .navbar-toggler {
        display: block;
    }
} */
.login-from-q {
  @media (max-widht: 1199px) {
    padding: 20px 32px;
  }
}
.login-from-q.payment-form {
  box-shadow: -0.083333333in 0.5pc 1.125pc -7px rgba(25, 42, 70, 0.13);
  border-radius: 7px;
  border: 1px solid #dee2e6;
}
.payment-form h3 {
  font-size: 35px;
  font-weight: 600;
  color: #000;
}
.pay-id strong,
.payment-summary strong,
.payment-meathod strong {
  color: #000;
  font-size: 17px;
}
.payment-heading span {
  margin-left: 10px;
  color: #f0151f;
  font-weight: 600;
}

 ul.booking_list {
  list-style: none;
  margin: 15px 0 25px 0;
  padding: 0;
}
ul.booking_list li {
  margin-bottom: 10px;
  display: flex;
  > * {
    width: 50%;
  }
  .btn {
    min-width: 80%;
  }
}
 ul.booking_list li span {
  font-size: 14px;
  font-weight: 600;
  // width: 50%;
  display: inline-block;
}
.login-from-q.payment-form form .form-group input {
  padding-left: 15px;
  border-radius: 10px;
}
.login-from-q.payment-form form .form-group input[type="select"] {
  padding-left: 15px;
  border-radius: 10px;
}
.login-from-q.payment-form form .form-group input:focus {
  box-shadow: none;
  border-color: #000000;
}
.payment-form .styled-select {
  width: 100%;
  overflow: hidden;
  height: 44px;
  background: #fff url(../images/arrow_down.svg) no-repeat right center;
  border: 1px solid #ced4da;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  border-radius: 0.25rem;
}
.payment-form .styled-select select {
  background: transparent;
  width: 107%;
  padding: 5px;
  padding-left: 15px;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  height: 41px;
  color: #868e95;
  margin: 0;
  font-weight: 400;
  -moz-appearance: window;
  -webkit-appearance: none;
  cursor: pointer;
  outline: none;
}

.home-form .form-group .form-control:focus,
.regis-form .form-group .form-control:focus {
  border-color: #f0151f87;
  box-shadow: 0 0 0 0.1rem #f0151f4a;
}
.btn_1.pay-btn {
  background: #18a84c;
  border-radius: 10px;
}
.btn_1.pay-btn:hover {
  color: #fff !important;
  background: #0f933e;
}
.btn_1.pay-cancel {
  background: transparent;
  color: red;
  border: 1px solid red;
  border-radius: 10px;
}
.btn_1.pay-cancel:hover {
  background: red;
  color: #fff !important;
}
.total-ammount,
.total-ammount span {
  color: #000;
  font-size: 20px;
}
.payment-summary {
  padding: 20px;
  background: #f7f7f7;
  &.payment-summary-modal {
    background: transparent;
    padding: 0;
    position: relative;
    .modal-close {
      position: absolute;
      right: 0px;
      top: -12px;
      font-size: 28px;
    }
  }
}

@media only screen and (max-width: 400px) {
  .mobile-user {
    font-size: 13px;
    padding: 5px;
  }
  .quan-toggle i.ti-menu {
    font-size: 22px;
  }
}
@media only screen and (max-width: 350px) {
  .logo_normal {
    height: 28px;
  }
  .logo_sticky {
    height: 28px;
  }
  .dropdown .dropdown-menu.res-menu {
    width: 225px;
  }
}
@media only screen and (max-width: 480px) {
  .login-from-q.payment-form {
    padding: 45px 25px 54px;
  }
}



.sales-chat-text{
  // max-width:800px;
  // margin: 0 auto;
}


.verification-badge-and-status{
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  @media (max-width: 1080px) {
    display: block;
    .badge,.btn{
      font-size: 14px;
    }
  }
  .text{
    @media (max-width: 1080px) {
      display: block;
      width: 100%;
      font-weight: bold;
      margin-bottom: 16px;

    }
  }
}


// admin onlty
.approvecompleted-table{
  
    th {
      vertical-align: middle!important;
    }
}

